import React from "react";
import AliceCarousel from "react-alice-carousel";
import { ReactComponent as LineTop_icon } from "../../style/images/line_top.svg";
import "react-alice-carousel/lib/alice-carousel.css";
import "./slider.css";

const renderPrevButton = ({ isDisabled }) => (
  <button
    className="carousel-button carousel-button-prev"
    disabled={isDisabled}
    type="button"
  >
    <LineTop_icon />
  </button>
);

const renderNextButton = ({ isDisabled }) => (
  <button
    type="button"
    className="carousel-button carousel-button-next"
    disabled={isDisabled}
  >
    <LineTop_icon />
  </button>
);

const renderDotsItem = ({ isActive }) => (
  <div className={`carousel-dot ${isActive ? "active" : ""}`} />
);

const UseSliderProductModal = ({ images }) => {
  return (
    <div className="carousel-containerFullsize">
      {images.length > 0 && (
        <AliceCarousel
          mouseTracking
          items={images}
          renderPrevButton={renderPrevButton}
          renderNextButton={renderNextButton}
          renderDotsItem={renderDotsItem}
          responsive={{
            0: { items: 1 },
          }}
          // controlsStrategy="alternate"
          controlsStrategy="responsive"
        />
      )}
    </div>
  );
};

export default UseSliderProductModal;
