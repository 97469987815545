export const makeUrl = ({ priceRange, filtertsCheckedNames }) => {
  let url = "";

  // Append price range parameters
  if (
    priceRange.min !== undefined &&
    priceRange.max !== undefined &&
    priceRange.max > 1
  ) {
    url += `&minPrice=${priceRange.min - 20}&maxPrice=${priceRange.max + 20}`;
  }

  // Append filter checked IDs
  if (filtertsCheckedNames && filtertsCheckedNames.length > 0) {
    url += `&${`filter=${JSON.stringify(filtertsCheckedNames)}`}`;
  }

  return url;
};
