import { useEffect } from "react";
import { useDispatch } from "react-redux";
import "../../style/basket.css";
import HamburgerContent from "./components/HamburgerContent";

const Hamburger = ({ open, toggle, Сontent = false, Icon, title = "" }) => {
  const dispatch = useDispatch();

  const toggleOpen = () => {
    dispatch(toggle());
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open]);
  return (
    <div className="header_basket-wrapper" onClick={toggleOpen}>
      {Icon}
      <HamburgerContent
        title={title}
        open={open}
        toggleOpen={toggleOpen}
        Сontent={Сontent}
      />
    </div>
  );
};

export default Hamburger;
