import React from "react";
import { ReactComponent as BugerIcon } from "../../../../../style/images/burger_icon.svg";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../../../../utils/LanguageContext";
const RenderMenuItem = ({
  menuItem,
  onSelect,
  selectedCategory,
  openCategory,
  onToggleCategory,
  isSubCategory = false,
  parentId = null,
  toggleHamburger,
}) => {
  const navigate = useNavigate();
  const { languageSlug: lang } = useLanguage();
  const handleOpen = (item) => {
    onToggleCategory(item.id);
  };

  const handleSelect = (item) => {
    onSelect(item);
  };

  if (menuItem.subMenu) {
    return (
      <div
        className="catalog_sideitem_wrapper"
        style={
          openCategory === menuItem.id && !isSubCategory
            ? {
                borderTop: "1px solid var(--green008947)",
                borderBottom: "1px solid var(--green008947)",
                padding: "5px 0",
              }
            : {}
        }
      >
        <div
          className={`catalog_sideitem
           ${openCategory === menuItem.id && "active"}
           ${selectedCategory === menuItem.id && "selected"}`}
        >
          {menuItem.icon}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px",
              width: "100%",
            }}
            onClick={() => {
              handleOpen(menuItem);
              handleSelect(menuItem);
              // TODO

              navigate(`/${lang}/catalog/${menuItem.id}`);
            }}
          >
            <span className="catalog_sideitem_titel">
              {menuItem[`name_${lang}`]}
            </span>
            <BugerIcon />
          </div>
        </div>

        {openCategory === menuItem.id && (
          <div className="catalog_aside_submenu">
            {menuItem.subMenu.map((subItem) => (
              <RenderMenuItem
                key={subItem.id}
                menuItem={subItem}
                onSelect={onSelect}
                selectedCategory={selectedCategory}
                openCategory={openCategory}
                onToggleCategory={onToggleCategory}
                isSubCategory={true}
                parentId={menuItem.id}
                toggleHamburger={toggleHamburger}
              />
            ))}
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      key={menuItem.id}
      onClick={() => {
        // TODO
        handleSelect(menuItem);

        if (isSubCategory) toggleHamburger();
        navigate(`/${lang}/catalog/${parentId}/${menuItem.id}`);
      }}
      className={`catalog_sideitem ${
        selectedCategory === menuItem.id && "selected"
      } ${isSubCategory ? "sub-category-item" : ""}`}
    >
      {menuItem.icon}
      <span style={{ padding: "10px" }} className="catalog_sideitem_titel">
        {menuItem[`name_${lang}`]}
      </span>
    </div>
  );
};

export default RenderMenuItem;
