import React, { useState, useEffect } from "react";
import PriceFilter from "./PriceFilter";
import FilterSection from "./FilterSection";
import CheckedOptions from "./CheckedOptions";
import { useDispatch } from "react-redux";
import { setFiltertsCheckedNames } from "../../../store/slices/catalogSlice";
import { filterTranslate } from "../../../utils/translate";
const Filters = ({
  filters,
  setFilters,
  initialFilters,
  lang,
  priceInitialRange,
  title = true,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    setFilters(
      initialFilters.map((filter) => ({
        ...filter,
        options: filter.options.map((option) => ({
          ...option,
          name: option[`name_${lang}`],
          checked: option.checked || false,
        })),
      }))
    );
  }, []);

  const handleFilterChange = (filterId, valueId, checked) => {
    setFilters((prevFilters) =>
      prevFilters.map((filter) =>
        filter.id === filterId
          ? {
              ...filter,
              options: filter.options.map((option) => {
                return option.id === valueId ? { ...option, checked } : option;
              }),
            }
          : filter
      )
    );
  };

  useEffect(() => {
    const groupedNames = filters.reduce((acc, filter) => {
      const checkedOptions = filter?.options
        .filter((option) => option.checked)
        .map((option) => {
          return {
            option_name_ua: option.name_ua,
            option_name_ru: option.name_ru,
            filter_id: filter.id,
          };
        });

      checkedOptions.forEach((checkedOption) => {
        const existingFilter = acc.find(
          (item) => item.attribute_id === checkedOption.filter_id
        );
        if (existingFilter) {
          existingFilter.option_names.push(checkedOption);
        } else {
          acc.push({
            attribute_id: checkedOption.filter_id,
            option_names: [checkedOption],
          });
        }
      });

      return acc;
    }, []);

    dispatch(setFiltertsCheckedNames(groupedNames));
  }, [filters]);

  return (
    <div className="filters">
      {title && (
        <span className="filters_title">{filterTranslate.filter[lang]}:</span>
      )}
      <CheckedOptions setFilters={setFilters} lang={lang} />
      <PriceFilter min={priceInitialRange.min} max={priceInitialRange.max} />
      {filters.map((filter) => (
        <FilterSection
          key={filter.id}
          title={filter[`title_${lang}`]}
          options={filter.options}
          onFilterChange={(valueId, checked) =>
            handleFilterChange(filter.id, parseInt(valueId), checked)
          }
        />
      ))}
    </div>
  );
};

export default Filters;
