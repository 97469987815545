import React, { useEffect } from "react";
import { useLanguage } from "../../utils/LanguageContext";
import HomeHelmet from "./components/HomeHelmet";
import HomePartners from "./components/HomePartners";
import HomeСarousel from "./components/HomeСarousel";

import "../../style/home.css";
const Home = () => {
  const { languageSlug } = useLanguage();

  return (
    <div style={{ width: "100%" }}>
      <HomeHelmet lang={languageSlug} />

      <HomeСarousel lang={languageSlug} />
      <HomePartners lang={languageSlug} />
    </div>
  );
};

export default Home;
