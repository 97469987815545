import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import InputMask from "react-input-mask";
import * as Yup from "yup";
import { signUp } from "../../store/slices/userSlice";
import { orderFormTranslate, signInTranslate } from "../../utils/translate";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
const SignUpForm = ({ lang }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const recaptchaRef = React.createRef();
  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        phone: "",
        // recaptcha: "",
      }}
      validationSchema={Yup.object({
        name: Yup.string().required("Enter П.І.Б!"),
        phone: Yup.string().required("Enter Номер телефону!"),
        email: Yup.string()
          .trim()
          .required("Enter email")
          .email("Некорректный email"),
        recaptcha: Yup.string().required("Please complete the reCAPTCHA"),
      })}
      onSubmit={async (body) => {
        const req = await dispatch(signUp(body));
        if (req.payload === 200) navigate(`/${lang}/account`);
      }}
    >
      {({ errors, touched, handleChange, handleBlur, setFieldValue }) => (
        <Form className="">
          <div className="orderForm_input-wrapper">
            <ErrorMessage name="name" className="error user" component="div" />
            <span
              className="signUp_title"
              style={{
                fontSize: "20px",
                fontWeight: "normal",
                marginBottom: "16px",
                color: "#456d64",
              }}
            >
              {orderFormTranslate.name[lang]}:
            </span>
            <Field
              style={{ width: "100%" }}
              className=""
              type="text"
              placeholder={orderFormTranslate.name[lang]}
              name="name"
            />
          </div>

          <div className="orderForm_input-wrapper">
            <ErrorMessage name="phone" className="error user" component="div" />
            <span
              className="signUp_title"
              style={{
                fontSize: "20px",
                fontWeight: "normal",
                marginBottom: "16px",
                color: "#456d64",
              }}
            >
              {orderFormTranslate.phone[lang]}:
            </span>
            <Field name="phone">
              {({ field }) => (
                <InputMask
                  {...field}
                  style={{ width: "100%" }}
                  mask="+38(099) 999-9999"
                  id="phone"
                  placeholder={orderFormTranslate.phone[lang]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className=""
                />
              )}
            </Field>
          </div>

          <div className="orderForm_input-wrapper">
            <ErrorMessage name="email" className="error user" component="div" />
            <span
              className="signUp_title"
              style={{
                fontSize: "20px",
                fontWeight: "normal",
                marginBottom: "16px",
                color: "#456d64",
              }}
            >
              E-mail:
            </span>
            <Field
              className=""
              style={{ width: "100%" }}
              type="text"
              placeholder="E-mail"
              name="email"
            />
          </div>

          <div className="orderForm_input-wrapper">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LeoNQQqAAAAAPLtZp6hUMtmQ8CqrTQ0SPjRnfNe"
              onChange={(value) => setFieldValue("recaptcha", value)}
            />
            <ErrorMessage name="recaptcha" component="div" />
          </div>

          <button
            className="orderForm_button"
            style={{
              width: "fit-content",
              fontSize: "20px",
              marginTop: "32px",
            }}
            type="submit"
          >
            {signInTranslate.signUp[lang]}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default SignUpForm;
