import React from "react";
import ReactDOM from "react-dom/client";
import AppRouter from "./routes/AppRouter";
import { BrowserRouter as Router } from "react-router-dom";
// import { HashRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store/store";
import { Provider } from "react-redux";
import { LanguageProvider } from "./utils/LanguageContext";
import { HelmetProvider } from "react-helmet-async";
import "./style/index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <LanguageProvider>
          <HelmetProvider>
            <AppRouter />
          </HelmetProvider>
        </LanguageProvider>
      </Router>
    </PersistGate>
  </Provider>
);
