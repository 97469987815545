import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../components/header/Header";
import Nav from "../components/nav/Nav";
import Footer from "../components/footer/Footer";

// Page
import Home from "../views/home/Home";
import Сatalog from "../views/catalog/Сatalog";
import FilterWithProducts from "../views/filterWithProducts/FilterWithProducts";
import ProductPage from "../views/product/ProductPage";
import Order from "../views/order/Order";
import SignIn from "../views/signIn/SignIn";
import Account from "../views/account/Account";

const AppRouter = () => {
  return (
    <>
      <Header />
      <Nav />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/signIn" element={<SignIn />} />
          <Route path="/account" element={<Account />} />

          <Route path="/catalog/*" element={<Сatalog />} />
          <Route path="/catalog/:category" element={<Сatalog />} />
          <Route
            path="/catalog/:category/:subCategory"
            element={<FilterWithProducts />}
          />
          <Route
            path="/catalog/:category/:subCategory/:product"
            element={<ProductPage />}
          />

          <Route path="/order" element={<Order />} />

          <Route path="*" element={<Home />} />
        </Routes>
      </main>
      <Footer />
    </>
  );
};

export default AppRouter;
