export const findCategoryById = ({ id, data }) => {
  const search = (items) => {
    for (const item of items) {
      if (item.id === id) {
        return item;
      }
      if (item.subMenu) {
        const found = search(item.subMenu);
        if (found) return found;
      }
    }
    return null;
  };

  return search(data);
};
export const findCategoryByName = ({ name, data, lang }) => {
  const search = (items) => {
    for (const item of items) {
      if (item[`name_${lang}`] === name) {
        return item;
      }
      if (item.subMenu) {
        const found = search(item.subMenu);
        if (found) return found;
      }
    }
    return null;
  };

  return search(data);
};
