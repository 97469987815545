import React, { useState } from "react";
import { ReactComponent as LineTop_icon } from "../../style/images/line_top.svg";
import "./slider.css";
// import { IMG_URL } from "../../api";
const VerticalSlider = ({ images, selectedIndex, setSelectedIndex }) => {
  const [touchStartY, setTouchStartY] = useState(null);
  const [touchMoveY, setTouchMoveY] = useState(null);

  const handleTouchStart = (e) => {
    setTouchStartY(e.touches[0].clientY);
    setTouchMoveY(null);
  };

  const handleTouchMove = (e) => {
    if (touchStartY !== null) {
      setTouchMoveY(e.touches[0].clientY);
    }
  };

  const handleTouchEnd = () => {
    if (touchStartY !== null && touchMoveY !== null) {
      handleSwipe();
    }
    setTouchStartY(null);
    setTouchMoveY(null);
  };

  const handleSwipe = () => {
    const swipeThreshold = 50;

    if (touchStartY - touchMoveY > swipeThreshold) {
      handleNext();
    } else if (touchMoveY - touchStartY > swipeThreshold) {
      handlePrev();
    }
  };

  const handlePrev = () => {
    const currentIndex = images.findIndex((img) => img === selectedIndex);
    const newIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
    setSelectedIndex(images[newIndex]);
  };

  const handleNext = () => {
    const currentIndex = images.findIndex((img) => img === selectedIndex);
    const newIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;
    setSelectedIndex(images[newIndex]);
  };

  return (
    <div
      className="carousel-container"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <button
        className="carousel-button carousel-button-prev"
        onClick={handlePrev}
      >
        <LineTop_icon />
      </button>
      <div className="carousel-wrapper">
        <div className="carousel-content">
          {images.map((image, index) => (
            <div
              key={index}
              className={`carousel-item ${
                image === selectedIndex ? "active" : ""
              }`}
              style={{
                transform: `translateY(-${
                  images.indexOf(selectedIndex) * 100
                }%)`,
              }}
              onClick={() => setSelectedIndex(image)}
            >
              <img src={image} alt={`Slide ${index}`} />
            </div>
          ))}
        </div>
      </div>
      <button
        className="carousel-button carousel-button-next"
        onClick={handleNext}
      >
        <LineTop_icon />
      </button>
    </div>
  );
};

export default VerticalSlider;
