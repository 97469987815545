import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import InputMask from "react-input-mask";
import * as Yup from "yup";
import {
  createCounterpartiesInvoice,
  clearBasket,
} from "../../../store/slices/orderSlice";
import { orderFormTranslate } from "../../../utils/translate";
import ReCAPTCHA from "react-google-recaptcha";
const OrderForm = ({ setShow, lang }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.order.basket);
  const userData = useSelector((state) => state.user.data);
  const recaptchaRef = React.createRef();
  return (
    <Formik
      initialValues={{
        name: userData ? userData.name : "",
        email: userData ? userData.email : "",
        comment: "",
        phone: userData ? userData.phone : "",
        recaptcha: "",
      }}
      validationSchema={Yup.object({
        name: Yup.string().required("Enter П.І.Б!"),
        phone: Yup.string().required("Enter Номер телефону!"),
        email: Yup.string()
          .trim()
          .required("Enter email")
          .email("Некорректный email"),
        recaptcha: Yup.string().required("Please complete the reCAPTCHA"),
      })}
      onSubmit={async (body) => {
        const formData = new FormData();
        Object.entries(body).map(([key, value]) => {
          return formData.append(`${key}`, value);
        });
        data.forEach(({ product, quantity }) => {
          formData.append(
            "items",
            JSON.stringify({ id: product.id, quantity })
          );
        });
        const req = await dispatch(createCounterpartiesInvoice(formData));
        if (req.payload === 200) {
          dispatch(clearBasket());
          setShow(false);
        }
      }}
    >
      {({ errors, touched, handleChange, handleBlur, setFieldValue }) => (
        <div className="orderForm-wrapper">
          <span className="orderForm_title">
            {orderFormTranslate.title[lang]}
          </span>

          <Form className="">
            <div className="orderForm_input-wrapper">
              <span>{orderFormTranslate.name[lang]}:</span>
              <Field
                className=""
                type="text"
                placeholder={orderFormTranslate.name[lang]}
                name="name"
              />
            </div>

            <div className="orderForm_input-wrapper">
              <span>{orderFormTranslate.phone[lang]}:</span>
              <Field name="phone">
                {({ field }) => (
                  <InputMask
                    {...field}
                    mask="+38(099) 999-9999"
                    id="phone"
                    placeholder={orderFormTranslate.phone[lang]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className=""
                  />
                )}
              </Field>
            </div>

            <div className="orderForm_input-wrapper">
              <span>E-mail:</span>
              <Field
                className=""
                type="text"
                placeholder="E-mail"
                name="email"
              />
            </div>

            <div className="orderForm_input-wrapper">
              <span>{orderFormTranslate.comment[lang]}:</span>
              <Field as="textarea" type="text" name="comment" />
            </div>

            <div className="orderForm_input-wrapper">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LeoNQQqAAAAAPLtZp6hUMtmQ8CqrTQ0SPjRnfNe"
                onChange={(value) => setFieldValue("recaptcha", value)}
              />
              <ErrorMessage name="recaptcha" component="div" />
            </div>

            <button className="orderForm_button" type="submit">
              {orderFormTranslate.submit[lang]}
            </button>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default OrderForm;
