import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCounterparyPassword } from "../../../store/slices/userSlice";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { accountTranslate } from "../../../utils/translate";
import Spinner from "../../../utils/spiner/Spinner";
const AccountPassword = ({ lang }) => {
  const dispatch = useDispatch();
  const { error: errorSignIn, loading } = useSelector((state) => state.user);
  return (
    <Formik
      initialValues={{
        old_pass: "",
        new_pass_1: "",
        new_pass_2: "",
      }}
      validationSchema={Yup.object({
        old_pass: Yup.string().required("Enter your current password!"),
        new_pass_1: Yup.string()
          .required("Enter your new password!")
          .min(6, "Password must be at least 6 characters long!"),
        new_pass_2: Yup.string()
          .required("Confirm your new password!")
          .oneOf([Yup.ref("new_pass_1"), null], "Passwords must match!"),
      })}
      onSubmit={async (body, { resetForm }) => {
        const formData = new FormData();
        formData.append(`password`, body.old_pass);
        formData.append(`new_password`, body.new_pass_1);

        const req = await dispatch(updateCounterparyPassword(formData));
        if (req.payload === 200) {
          resetForm();
        }
      }}
    >
      {({ errors, touched, handleChange, handleBlur }) => (
        <div className="accountContact">
          {/* {errorSignIn ? <div>Wrong password or login</div> : ""} */}
          <ErrorMessage
            name="old_pass"
            className="error user"
            component="div"
          />
          <ErrorMessage
            name="new_pass_1"
            className="error password"
            component="div"
          />
          <ErrorMessage
            name="new_pass_2"
            className="error password"
            component="div"
          />
          {errorSignIn ? <div>Wrong password or login</div> : ""}
          {loading && <Spinner />}
          <Form>
            <div className="accountContact_item-wrapper">
              <div className="accountContact_item">
                <label htmlFor="old_pass">Пароль</label>
                <Field
                  className=""
                  type="password"
                  placeholder=""
                  name="old_pass"
                />
              </div>

              <div className="accountContact_item">
                <label htmlFor="new_pass_1">
                  {accountTranslate.new[lang]} пароль
                </label>
                <Field
                  className="pass"
                  id="new_pass_1"
                  type="password"
                  placeholder=""
                  name="new_pass_1"
                />
              </div>

              <div className="accountContact_item">
                <label htmlFor="new_pass_2">
                  {accountTranslate.confirmNew[lang]} пароль
                </label>
                <Field
                  className="pass"
                  id="new_pass_2"
                  type="password"
                  placeholder=""
                  name="new_pass_2"
                />
              </div>
            </div>

            <button type="submit">Зберегти</button>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default AccountPassword;
