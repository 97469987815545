import React from "react";
import { motion } from "framer-motion";
import aosite_img from "../../../style/images/aosite.png";
import gtv_img from "../../../style/images/gtv.png";
import hafele_img from "../../../style/images/hafele.png";
import biom_img from "../../../style/images/biom.png";

import dc_img from "../../../style/images/dc.png";
import abris_img from "../../../style/images/abris.png";
import viyar_img from "../../../style/images/viyar.png";
const HomePartners = ({ lang }) => {
  return (
    <div className="homePartners">
      <div className="homePartners_title">
        {lang === "ua" ? (
          <>
            <span>НАШІ </span> <span>ПАРТНЕРИ</span>
          </>
        ) : (
          <>
            <span>НАШИ </span> <span>ПАРТНЕРЫ</span>
          </>
        )}
      </div>

      <div className="homePartners_card">
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="homePartners_card_textWrapper"
        >
          {lang === "ua" ? (
            <>
              <span className="homePartners_card_title">GTV</span>
              <span className="homePartners_card_promo">
                Польська компанія
                <span className="homePartners_card_promo_title">GTV</span>
                належить до найбільших міжнародних виробників меблевої фурнітури
                та освітлення. Її продукція здобула широку популярність у
                країнах Азії та Європи, а нещодавно почала користуватися попитом
                і на ринках України.
              </span>
            </>
          ) : (
            <>
              <span className="homePartners_card_title">GTV</span>
              <span className="homePartners_card_promo">
                Польская компания
                <span className="homePartners_card_promo_title">GTV</span>
                принадлежит к крупнейшим международным производителям мебельной
                фурнитуры и освещение. Ее продукция приобрела широкую
                популярность в странах Азии и Европы, а недавно стала
                пользоваться спросом и на рынках Украины.
              </span>
            </>
          )}
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="homePartners_card_img_wrapper"
        >
          <img
            className="homePartners_card_img"
            src={gtv_img}
            alt="parnter image"
          />
        </motion.div>
      </div>

      <div className="homePartners_card">
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="homePartners_card_img_wrapper"
        >
          <img
            className="homePartners_card_img"
            src={hafele_img}
            alt="parnter image"
          />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="homePartners_card_textWrapper"
        >
          {lang === "ua" ? (
            <>
              <span className="homePartners_card_title">HÄFELE</span>
              <span className="homePartners_card_promo">
                Міжнародна компанія з інтелектуальних технологій фурнітури,
                освітлення та систем замків. Історія компанії починається в
                Німеччині, а потім стрімко і глобально розвинулося. Сьогодні
                клієнти з понад 150 країн світу довіряють нашому досвіду. Понад
                38 дочірніх компаній і численні інші представництва по всьому
                світу є частиною сім'ї
                <span className="homePartners_card_promo_title">HÄFELE</span>.
              </span>
            </>
          ) : (
            <>
              <span className="homePartners_card_title">HÄFELE</span>
              <span className="homePartners_card_promo">
                Международная компания по интеллектуальным технологиям
                фурнитуры, освещение и системы замков. История компании
                начинается в Германии, а затем стремительно и глобально
                развилось. Сегодня клиенты из более чем 150 стран мира доверяют
                нашему опыту. Более 38 дочерних компаний и многочисленные другие
                представительства по всему миру являются частью семьи
                <span className="homePartners_card_promo_title">HÄFELE</span>.
              </span>
            </>
          )}
        </motion.div>
      </div>

      <div className="homePartners_card">
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="homePartners_card_textWrapper"
        >
          {lang === "ua" ? (
            <>
              <span className="homePartners_card_title">BIOM</span>
              <span className="homePartners_card_promo">
                Компанія
                <span className="homePartners_card_promo_title">BIOM</span>– це
                передові технології, професійні співробітники, застосування
                прогресивного обладнання, найкраща сировину та висока якість
                кінцевої продукції. Ми пропонуємо оптимальні рішення в області
                світлодіодного освітлення за доступними цінами та з повноцінною
                заводською гарантією.
              </span>
            </>
          ) : (
            <>
              <span className="homePartners_card_title">BIOM</span>
              <span className="homePartners_card_promo">
                Компания
                <span className="homePartners_card_promo_title">BIOM</span>– это
                передовые технологии, профессиональные сотрудники, применение
                прогрессивного оборудования, лучшее сырье и высокое качество
                конечной продукции. Мы предлагаем оптимальные решения в области
                светодиодное освещение по доступным ценам и с полноценной
                заводской гарантией.
              </span>
            </>
          )}
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="homePartners_card_img_wrapper"
        >
          <img
            className="homePartners_card_img"
            src={biom_img}
            alt="parnter image"
          />
        </motion.div>
      </div>

      <div className="homePartners_card">
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="homePartners_card_img_wrapper"
        >
          <img
            className="homePartners_card_img"
            src={aosite_img}
            alt="parnter image"
          />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="homePartners_card_textWrapper"
        >
          {lang === "ua" ? (
            <>
              <span className="homePartners_card_title">AOSITE</span>
              <span className="homePartners_card_promo">
                Компанія
                <span className="homePartners_card_promo_title">AOSITE</span>
                Hardware Precision Manufacturing Co.LTD була заснована в 1993
                році Гаояо, яка відома як «Країна обладнання».
                <span className="homePartners_card_promo_title">AOSITE</span>є
                провідним постачальником металевих висувних систем. Продукція
                включає петлі, газові пружини, висувні ящики, ручки шаф.
              </span>
            </>
          ) : (
            <>
              <span className="homePartners_card_title">AOSITE</span>
              <span className="homePartners_card_promo">
                Компания
                <span className="homePartners_card_promo_title">AOSITE</span>
                Hardware Precision Manufacturing Co.LTD была основана в 1993
                году в Гаояо, которая известна как «Страна оборудования».
                <span className="homePartners_card_promo_title">AOSITE</span>
                является ведущим поставщиком металлических выдвижных систем.
                Продукция включает петли, газовые пружины, выдвижные ящики,
                ручки шкафов.
              </span>
            </>
          )}
        </motion.div>
      </div>

      <div className="homePartners_card">
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="homePartners_card_textWrapper"
        >
          {lang === "ua" ? (
            <>
              <span className="homePartners_card_title">DС</span>
              <span className="homePartners_card_promo">
                Національний постачальник меблевої фурнітури. Наша спеціалізація
                - виробництво і реалізація меблевої фурнітури в B2B сегменті на
                території України і Європи під брендом
                <span className="homePartners_card_promo_title">DС.</span>
                Перебуваючи в постійному пошуку, ми прагнемо запропонувати
                найбільш вигідні умови для наших партнерів і клієнтів.
              </span>
            </>
          ) : (
            <>
              <span className="homePartners_card_title">DС</span>
              <span className="homePartners_card_promo">
                Национальный поставщик фурнитуры. Наша специализация -
                производство и реализация мебельной фурнитуры в сегменте B2B на
                территории Украины и Европы под брендом
                <span className="homePartners_card_promo_title">DS.</span>
                Находясь в постоянном поиске, мы стремимся предложить наиболее
                выгодные условия для наших партнеров и клиентов.
              </span>
            </>
          )}
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="homePartners_card_img_wrapper"
        >
          <img
            className="homePartners_card_img"
            src={dc_img}
            alt="parnter image"
          />
        </motion.div>
      </div>

      <div className="homePartners_card">
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="homePartners_card_img_wrapper"
        >
          <img
            className="homePartners_card_img"
            src={abris_img}
            alt="parnter image"
          />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="homePartners_card_textWrapper"
        >
          {lang === "ua" ? (
            <>
              <span className="homePartners_card_title">ABRIS</span>
              <span className="homePartners_card_promo">
                Компанія
                <span className="homePartners_card_promo_title">«ABRIS»</span>
                динамічно розвивається на ринку України вже понад 20 років.
                Пропонують широкий асортимент меблевої фурнітури, плитних
                матеріалів, шаф-купе, організації простору і гардеробів. Також
                повний спектр послуг по розпилюванню і торцюванню плитних
                матеріалів на найсучаснішому обладнанні провідних світових
                виробників.
              </span>
            </>
          ) : (
            <>
              <span className="homePartners_card_title">ABRIS</span>
              <span className="homePartners_card_promo">
                Компания
                <span className="homePartners_card_promo_title">«ABRIS»</span>
                динамично развивается на рынке Украины уже более 20 лет.
                Предлагают широкий ассортимент мебельной фурнитуры, плитных.
                материалов, шкафов-купе, организации пространства и гардеробов.
                Также полный спектр услуг по распиловке и торцовке плитных.
                материалов на самом современном оборудовании ведущих мировых
                производителей.
              </span>
            </>
          )}
        </motion.div>
      </div>

      <div className="homePartners_card">
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="homePartners_card_textWrapper"
        >
          {lang === "ua" ? (
            <>
              <span className="homePartners_card_title">viyar</span>
              <span className="homePartners_card_promo">
                <span className="homePartners_card_promo_title">«viyar»</span> є
                одним з провідних українських підприємств із продажу меблевих
                комплектуючих та надання супутніх послуг. Компанія заснована у
                2002 році. Сьогодні мережа
                <span className="homePartners_card_promo_title">«viyar»</span>
                представлена у багатьох філіях по всій Україні.
              </span>
            </>
          ) : (
            <>
              <span className="homePartners_card_title">viyar</span>
              <span className="homePartners_card_promo">
                <span className="homePartners_card_promo_title">«viyar»</span>
                одним из ведущих украинских предприятий по продаже мебельных
                комплектующих и оказание сопутствующих услуг. Компания основана
                в 2002 году. Сегодня сеть
                <span className="homePartners_card_promo_title">«viyar»</span>
                представлена ​​во многих филиалах по всей Украине.
              </span>
            </>
          )}
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="homePartners_card_img_wrapper"
        >
          <img
            className="homePartners_card_img"
            src={viyar_img}
            alt="parnter image"
          />
        </motion.div>
      </div>
    </div>
  );
};

export default HomePartners;
