import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import $api from "../../api";

export const createCounterpartiesInvoice = createAsyncThunk(
  "counterparty/createCounterpartiesInvoice",
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api.post("/invoices", formData);

      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const order = createSlice({
  name: "order",
  initialState: {
    basket: [],
    basketOpen: false,
    error: null,
    loading: false,
    version: 1,
  },
  reducers: {
    addBasketData(state, action) {
      const existingProductIndex = state.basket.findIndex(
        (item) => item.product.id === action.payload.product.id
      );

      if (existingProductIndex !== -1) {
        state.basket[existingProductIndex].quantity = parseInt(
          action.payload.quantity
        );
      } else {
        state.basket.push(action.payload);
      }
    },
    updateBasketItemQuantity(state, { payload }) {
      state.basket = state.basket.map(({ product, quantity }) => {
        if (product.id === payload.product_id) {
          const value = payload.quantity < 0 ? 1 : payload.quantity;

          return { product, quantity: value };
        }
        return { product, quantity };
      });
    },
    toggleOpenBasket(state) {
      state.basketOpen = !state.basketOpen;
    },
    clearBasket(state) {
      state.basket = [];
    },
    delItemBasket(state, action) {
      const product_id = action.payload;
      const newBasket = state.basket.filter(
        ({ product }) => product.id !== product_id
      );
      state.basket = newBasket;
    },
  },
});
export const {
  addBasketData,
  clearBasket,
  delItemBasket,
  toggleOpenBasket,
  updateBasketItemQuantity,
} = order.actions;

export default order.reducer;
