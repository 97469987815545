import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import $api from "../../api";

export const signIn = createAsyncThunk(
  "user/signIn",
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const response = await $api.post("/shop/signin", formData);
      localStorage.setItem("token", response.data.access);
      dispatch(setUser(response.data));
      return response.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const signUp = createAsyncThunk(
  "user/signUp",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await $api.post("/counterparty", data);

      localStorage.setItem("token", response.data.access);
      dispatch(setUser(response.data));
      return response.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateCounterpary = createAsyncThunk(
  "user/updateCounterpary",
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const response = await $api.put("/counterparty/account", formData);

      dispatch(updateUser(response.data.data));
      return response.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const updateDiscountCounterpary = createAsyncThunk(
  "user/updateDiscountCounterpary",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await $api.get(`/counterparty_discount/${id}`);

      dispatch(updateDiscount(response.data.discount));
      return response.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const updateCounterparyPassword = createAsyncThunk(
  "user/updateCounterparyPassword",
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const response = await $api.put(
        "/counterparty/account/password",
        formData
      );

      dispatch(updateUser(response.data.data));
      return response.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getUserInvoices = createAsyncThunk(
  "user/getUserInvoices",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await $api("/invoices/account");

      dispatch(setTotalInvoices(response.data.invoices));
      return response.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  access: null,
  lang: "ua",
  data: null,
  invoices: [],
  totalInvoice: 0,
  activeNav: "contact",
  discount: 0,
  loading: false,
  error: null,
  hamburger: false,
};

const rejected = (state, action) => {
  state.loading = false;
  state.error = action.payload;
};
const fulfilled = (state) => {
  state.loading = false;
  state.error = null;
};
const pending = (state) => {
  state.loading = true;
  state.error = null;
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      state.access = action.payload.access;
      state.data = action.payload.data;
      state.discount = action.payload.data?.Discount?.value || 0;
    },
    updateDiscount(state, action) {
      state.discount = action.payload || 0;
    },

    updateUser(state, action) {
      state.data = action.payload;
    },
    setLang(state, action) {
      state.lang = action.payload;
    },
    setTotalInvoices(state, action) {
      state.invoices = action.payload.data;
      state.totalInvoice = action.payload.total;
    },
    setActiveNav(state, action) {
      state.activeNav = action.payload;
    },
    logout(state) {
      state.access = null;
      state.data = null;
      localStorage.removeItem("token");
    },
    toggleOpenHamburger(state) {
      state.hamburger = !state.hamburger;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => pending(state))
      .addCase(signIn.fulfilled, (state) => fulfilled(state))
      .addCase(signIn.rejected, (state, action) => rejected(state, action))

      .addCase(updateCounterpary.pending, (state) => pending(state))
      .addCase(updateCounterpary.fulfilled, (state) => fulfilled(state))
      .addCase(updateCounterpary.rejected, (state, action) =>
        rejected(state, action)
      )

      .addCase(updateCounterparyPassword.pending, (state) => pending(state))
      .addCase(updateCounterparyPassword.fulfilled, (state) => fulfilled(state))
      .addCase(updateCounterparyPassword.rejected, (state, action) =>
        rejected(state, action)
      );
  },
});

export const {
  setUser,
  setLang,
  logout,
  setUserDataForTable,
  setTotalCounterparties,
  setActiveNav,
  updateUser,
  setTotalInvoices,
  toggleOpenHamburger,
  updateDiscount,
} = userSlice.actions;

export default userSlice.reducer;
