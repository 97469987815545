import React, { useEffect, useRef, useState } from "react";
import OrderForm from "./components/OrderForm";
import OrderBasket from "./components/OrderBasket";
import { useLanguage } from "../../utils/LanguageContext";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { orderTranslate } from "../../utils/translate";
import "../../style/order.css";
const Order = () => {
  const orderRef = useRef(null);
  const navigate = useNavigate();
  const { languageSlug } = useLanguage();
  const [show, setShow] = useState(true);
  const user = useSelector((state) => state.user.access);
  useEffect(() => {
    if (orderRef.current) {
      orderRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <>
      {show ? (
        <div className="orderPage" ref={orderRef}>
          <span className="orderPage_title">
            {orderTranslate.order[languageSlug]}
          </span>
          <div className="orderPage_main-wrapper">
            <OrderForm lang={languageSlug} setShow={setShow} />
            <OrderBasket lang={languageSlug} />
          </div>
          <span className="orderPage_textBottom">
            {orderTranslate.textBottom[languageSlug]}
          </span>
        </div>
      ) : (
        <div className="orderPage">
          <span className="orderPage_title">
            {orderTranslate.orderThanks[languageSlug]}
          </span>
          <div className="orderSubmitPage">
            <span className="orderSubmitPage_title">
              {orderTranslate.orderConfirm[languageSlug]}
            </span>
            <span className="orderSubmitPage_text">
              {orderTranslate.call[languageSlug]}
            </span>
            <span className="orderSubmitPage_text">
              {orderTranslate.email[languageSlug]}
            </span>
            <span className="orderSubmitPage_text">
              {orderTranslate.orderThanks2[languageSlug]}
            </span>

            <div className="orderSubmitPage_button">
              <button onClick={() => navigate(`/${languageSlug}/catalog`)}>
                {orderTranslate.buttonCatalogue[languageSlug]}
              </button>
              <button
                onClick={() =>
                  navigate(
                    user
                      ? `/${languageSlug}/account`
                      : `/${languageSlug}/signIn`
                  )
                }
              >
                {orderTranslate.buttonAccount[languageSlug]}
              </button>
            </div>
            <span className="orderSubmitPage_bottom">
              {orderTranslate.promo[languageSlug]}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default Order;
