import React, { useEffect } from "react";
import { getUserInvoices } from "../../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { accountTranslate, statusTranslate } from "../../../utils/translate";
import { IMG_URL } from "../../../api";
// for dev test data
import { containsLoremFlickr } from "../../../utils/checkImg";
const AccountOrders = ({ lang }) => {
  const dispatch = useDispatch();
  const { invoices } = useSelector((state) => state.user);

  const statusOrder = (status) => {
    switch (status) {
      case "pending":
        return statusTranslate.pending[lang];
      case "сonfirmed":
        return statusTranslate.сonfirmed[lang];
      case "shipped":
        return statusTranslate.shipped[lang];
      case "delivered":
        return statusTranslate.delivered[lang];
      case "cancelled":
        return statusTranslate.cancelled[lang];
    }
  };
  useEffect(() => {
    dispatch(getUserInvoices());
  }, []);

  return (
    <div className="accountOrders">
      <div className="accountOrders_thead">
        <span>№ {accountTranslate.ordersNumber[lang]}</span>
        <span>{accountTranslate.orderProductName[lang]}</span>
        <span>{accountTranslate.orderProductQuantity[lang]}</span>
        <span>{accountTranslate.orderProductPrice[lang]}</span>
      </div>

      <div className="accountOrders_tbody">
        {invoices &&
          invoices.map((invoice, index) => (
            <div
              key={invoice.id}
              className="accountOrders_tbody_order-container"
              style={
                index > 0 ? { borderTop: "1px solid var(--green008947)" } : {}
              }
            >
              <span className="accountOrders_tbody_order_title">
                {index + 1}. Номер {`${accountTranslate.ordersNumber[lang]} `}
                {invoice.id}
              </span>
              {invoice?.CounterpartiesInvoicesItems.map(
                ({ Product, price_in_uah, quantity, id }, index) => (
                  <div
                    key={id}
                    className="accountOrders_tbody_order_product"
                    style={index > 0 ? { borderTop: "none" } : {}}
                  >
                    {Product && (
                      <>
                        <img
                          className="accountOrders_tbody_order_product_img"
                          // src={IMG_URL + Product.img_path}
                          src={
                            containsLoremFlickr(Product.img_path)
                              ? Product.img_path
                              : IMG_URL + Product.img_path
                          }
                          alt=""
                        />
                      </>
                    )}
                    <span className="accountOrders_tbody_order_product_name">
                      {Product?.name_ua}
                    </span>

                    <span className="accountOrders_tbody_order_product_quantity">
                      {quantity}
                    </span>
                    <span className="accountOrders_tbody_order_product_price">
                      {price_in_uah}₴
                    </span>
                  </div>
                )
              )}
              <div className="accountOrders_tbody_order_products-container">
                <div>Статус: {statusOrder(invoice?.status)}</div>
                <div>
                  <span>{accountTranslate.price[lang]}:</span>
                  <span>{invoice.price_sum_UAH}₴</span>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default AccountOrders;
