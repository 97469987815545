import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddButton from "../../../components/buttons/AddButton";
import { IMG_URL } from "../../../api";
import new_icon from "../../../style/images/new_icon.png";
import hit_icon from "../../../style/images/hit_icon.png";
import sale_icon from "../../../style/images/sale_icon.png";
// for dev test data
import { containsLoremFlickr } from "../../../utils/checkImg";
const ProductCardHorizontal = ({ product, lang }) => {
  const navigate = useNavigate();
  const { category, subCategory } = useParams();
  const productDetails = product?.Attributes?.filter(
    (item) => item.type === "main"
  ).map((item) => ({
    name_ua: item.title_ua,
    name_ru: item.title_ru,
    value_ua: item.product_has_attributes.value_ua,
    value_ru: item.product_has_attributes.value_ru,
    id: item.id,
  }));
  return (
    <div className="productCardHorizontal">
      <div className="productCard_addAttr">
        {product?.new_product && <img src={new_icon} alt="" />}
        {product?.top_product && <img src={hit_icon} alt="" />}
        {product?.sale && <img src={sale_icon} alt="" />}
      </div>
      <img
        className="productCardHorizontal_img"
        // src={IMG_URL + product.img_path}
        src={
          containsLoremFlickr(product.img_path)
            ? product.img_path
            : IMG_URL + product.img_path
        }
        alt="product image"
        onClick={() =>
          navigate(`/${lang}/catalog/${category}/${subCategory}/${product.id}`)
        }
      />
      <div className="productCardHorizontal_aside">
        <h2
          className="productCardHorizontal_title"
          onClick={() =>
            navigate(
              `/${lang}/catalog/${category}/${subCategory}/${product.id}`
            )
          }
        >
          {product[`name_${lang}`]}
        </h2>

        <span className="productCardHorizontal_article">
          <span>Артикул:</span> <span>{product.vendor_code}</span>
        </span>

        <div className="productCardHorizontal_characteristics">
          {productDetails &&
            productDetails.map((item) => (
              <div key={item.id}>
                <span>{item[`name_${lang}`]}:</span>
                <span>{item[`value_${lang}`]}</span>
              </div>
            ))}
        </div>

        <div className="productCardHorizontal_bottom">
          <div className="productCardHorizontal_bottom_price">
            <div className="productCardHorizontal_bottom_price_amount">
              {product?.price_in_uah}
            </div>
            <div className="productCardHorizontal_bottom_price_currency">
              грн/{product?.measured}
            </div>
          </div>
          <AddButton product={product} lang={lang} />
        </div>
      </div>
    </div>
  );
};

export default ProductCardHorizontal;
