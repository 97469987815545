import React from "react";
import { Helmet } from "react-helmet-async";
const ProductHelmet = ({ lang, productName }) => {
  const metaTags = (language) => {
    switch (language) {
      case "ru":
        return (
          <>
            <title>
              {productName} - Купить онлайн или офлайн | Дом Фурнитуры Одесса |
              Оптом и в розницу
            </title>
            <meta
              name="description"
              content={`${productName} доступен в магазине Дом Фурнитуры. Купите онлайн или оффлайн в Одессе. Предлагаем оптовые и розничные покупки. Высокое качество и выгодные цены. Заказывайте сейчас!`}
            />
            <meta
              name="keywords"
              content={`купить ${productName} Одесса, ${productName} для мебели, ${productName} цена, ${productName} недорого, ${productName} в наличии, ${productName} доставка Одесса, ${productName} отзывы, качественный ${productName}, ${productName} каталог, ${productName} магазин Дом фурнитуры, ${productName} для кухни, ${productName} для шкафов, ${productName} монтаж, ${productName} онлайн, ${productName} оптом,`}
            />
          </>
        );
      case "ua":
        return (
          <>
            <title>
              {productName} - Купити онлайн або офлайн | Дім Фурнітури Одеса |
              Оптом та в роздріб
            </title>
            <meta
              name="description"
              content={`${productName} доступний у магазині Дім Фурнітури. Купуйте онлайн або офлайн в Одесі. Пропонуємо оптові та роздрібні покупки. Висока якість і вигідні ціни. Замовляйте зараз!`}
            />
            <meta
              name="keywords"
              content={`купити ${productName} Одеса, ${productName} для меблів, ${productName} ціна, ${productName} недорого, ${productName} в наявності, ${productName} доставка Одеса, ${productName} відгуки, якісний ${productName}, ${productName} каталог, ${productName} магазин Дім фурнітури, ${productName} для кухні, ${productName} для шаф, ${productName} монтаж, ${productName} онлайн, ${productName} оптом,`}
            />
          </>
        );
    }
  };
  return <Helmet>{metaTags(lang)}</Helmet>;
};

export default ProductHelmet;
