import React from "react";
import { NavLink } from "react-router-dom";

import AliceCarousel from "react-alice-carousel";
import prev_icon from "../../../style/images/home_corousel_prev.png";
import next_icon from "../../../style/images/home_corousel_next.png";
import "react-alice-carousel/lib/alice-carousel.css";

const renderPrevButton = ({ isDisabled }) => (
  <button
    className="carousel-button carousel-button-prev"
    disabled={isDisabled}
    type="button"
  >
    <img src={prev_icon} alt="next image" />
  </button>
);

const renderNextButton = ({ isDisabled }) => (
  <button
    type="button"
    className="carousel-button carousel-button-next"
    disabled={isDisabled}
  >
    <img src={next_icon} alt="prev image" />
  </button>
);

const renderDotsItem = ({ isActive }) => (
  <div className={`carousel-dot ${isActive ? "active" : ""}`} />
);

const FirstItem = ({ lang }) => {
  return (
    <div className="homeCarousel_item first">
      <div className="homeCarousel_item_first_text">
        <div className="homeCarousel_item_first_wrapper">
          {lang === "ua" ? (
            <>
              <h1 className="homeCarousel_item_first_text_title">
                МЕБЛЕВА ФУРНІТУРА
              </h1>
              <span>Ідеальна фурнітура для вашого дому.</span>
              <span>
                Наша меблева фурнітура поєднує стиль та надійність. Сучасні
                петлі, елегантні ручки та плавні направляючі – все для вашого
                комфорту та затишку. Створіть ідеальні меблі з нами!
              </span>
              <NavLink
                className="homeCarousel_item_first_text_link"
                to={`/${lang}/catalog`}
              >
                ДОКЛАДНІШЕ
              </NavLink>
            </>
          ) : (
            <>
              <h1 className="homeCarousel_item_first_text_title">
                МЕБЕЛЬНАЯ ФУРНИТУРА
              </h1>
              <span>Идеальная фурнитура для вашего дома.</span>
              <span>
                Наша мебельная фурнитура совмещает стиль и надежность.
                Современные петли, элегантные ручки и плавные направляющие – все
                для вашего комфорта и уюта. Создайте идеальную мебель с нами!
              </span>
              <NavLink
                className="homeCarousel_item_first_text_link"
                to={`/${lang}/catalog`}
              >
                ПОДРОБНЕЕ
              </NavLink>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const SecondItem = ({ lang }) => {
  return (
    <div className="homeCarousel_item second">
      <div className="homeCarousel_item_second_text">
        {lang === "ua" ? (
          <>
            <h2 className="homeCarousel_item_second_text_title">
              Найбільш функціональні
            </h2>
            <span>Завіси для корпусних меблів</span>
            <span>які існують у кожному куточку нашого життя</span>
            <NavLink
              className="homeCarousel_item_second_text_link"
              to={`/${lang}/catalog/44`}
            >
              КУПИТИ
            </NavLink>
          </>
        ) : (
          <>
            <h2 className="homeCarousel_item_second_text_title">
              Наиболее функциональные
            </h2>
            <span>Петли для корпусной мебели</span>
            <span>существующих в каждом уголке нашей жизни</span>
            <NavLink
              className="homeCarousel_item_second_text_link"
              to={`/${lang}/catalog/44`}
            >
              КУПИТЬ
            </NavLink>
          </>
        )}
      </div>
    </div>
  );
};

const ThirdItem = ({ lang }) => {
  return (
    <div className="homeCarousel_item third">
      <div className="homeCarousel_item_third_text">
        {lang === "ua" ? (
          <>
            <h3 className="homeCarousel_item_third_text_title">ДУЖЕ МIЦНI</h3>
            <span>Направляючі для корпусних меблів</span>
            <span>
              які забезпечують легкий доступ до предметів, просто відкривши
              шухляду.
            </span>
            <NavLink
              className="homeCarousel_item_third_text_link"
              to={`/${lang}/catalog/45`}
            >
              КУПИТИ
            </NavLink>
          </>
        ) : (
          <>
            <h3 className="homeCarousel_item_third_text_title">
              ОЧЕНЬ КРЕПКИЙ
            </h3>
            <span>Направляющие для корпусной мебели</span>
            <span>
              которые обеспечивают легкий доступ к предметам, просто открыв
              ящик.
            </span>
            <NavLink
              className="homeCarousel_item_third_text_link"
              to={`/${lang}/catalog/45`}
            >
              КУПИТЬ
            </NavLink>
          </>
        )}
      </div>
    </div>
  );
};
const FourthdItem = ({ lang }) => {
  return (
    <div className="homeCarousel_item fourth">
      <div className="homeCarousel_item_fourth_text">
        {lang === "ua" ? (
          <>
            <h4 className="homeCarousel_item_fourth_text_title">
              ПРИКРАСА ДЛЯ ЖИТЛА
            </h4>
            <span>Меблеві ручки</span>
            <span>
              найпомітніший аксесуар для шаф, гардеробів і висувних ящиків
            </span>
            <NavLink
              className="homeCarousel_item_fourth_text_link"
              to={`/${lang}/catalog/42`}
            >
              КУПИТИ
            </NavLink>
          </>
        ) : (
          <>
            <h2 className="homeCarousel_item_fourth_text_title">
              УКРАШЕНИЕ ДЛЯ ЖИЛЬЯ
            </h2>
            <span>Мебельные ручки</span>
            <span>
              самый заметный аксессуар для шкафов, гардеробов и выдвижных ящиков
            </span>
            <NavLink
              className="homeCarousel_item_fourth_text_link"
              to={`/${lang}/catalog/42`}
            >
              КУПИТЬ
            </NavLink>
          </>
        )}
      </div>
    </div>
  );
};

const HomeСarousel = ({ lang }) => {
  return (
    <div className="homeCarousel">
      <AliceCarousel
        mouseTracking
        autoPlay
        autoPlayInterval={5000}
        animationDuration={1000}
        infinite
        items={[
          <FirstItem lang={lang} />,
          <SecondItem lang={lang} />,
          <ThirdItem lang={lang} />,
          <FourthdItem lang={lang} />,
        ]}
        renderPrevButton={renderPrevButton}
        renderNextButton={renderNextButton}
        renderDotsItem={renderDotsItem}
        responsive={{
          0: { items: 1 },
        }}
        controlsStrategy="responsive"
      />
    </div>
  );
};

export default HomeСarousel;
