import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useLanguage } from "./LanguageContext";

const Breadcrumbs = ({ params = false }) => {
  const location = useLocation();
  const { languageSlug } = useLanguage();
  const pathnames = location.pathname
    .slice(4)
    .split("/")
    .map((x) => x);

  return (
    <nav>
      <ul
        className="breadcrumbs"
        style={{ display: "flex", padding: "28px 49px", color: "#393939B2" }}
      >
        {params &&
          params.map(({ title, to }, index) => {
            const makeTo = `/${languageSlug}/${pathnames
              .slice(0, index + 1)
              .join("/")}`;
            const isLast = index === params.length - 1;
            return (
              <li
                key={title + to}
                style={{ fontSize: "16px", padding: "0 5px" }}
              >
                {!isLast ? (
                  <Link to={makeTo} style={{ color: "#393939B2" }}>
                    {title} {"-"}
                  </Link>
                ) : (
                  <span>{title}</span>
                )}
              </li>
            );
          })}
      </ul>
    </nav>
  );
};

export default Breadcrumbs;
