import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "../../store/slices/userSlice";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { signInTranslate } from "../../utils/translate";
import { useLanguage } from "../../utils/LanguageContext";
import SignUpForm from "./SignUpForm.jsx";
import "../../style/signIn.css";
const SignIn = () => {
  const { languageSlug } = useLanguage();
  const [signUp, setSignUp] = useState(false);
  const dispatch = useDispatch();
  const errorSignIn = useSelector((state) => state.user.error);
  const navigate = useNavigate();
  return (
    <div className="signin-page" style={{ margin: "32px 0px" }}>
      <div className="signin-page_wrapper">
        {signUp ? (
          <div className="signin-page_wrapper-left">
            <SignUpForm lang={languageSlug} />
          </div>
        ) : (
          <div className="signin-page_wrapper-left">
            <span>{signInTranslate.record[languageSlug]}</span>
            <span>{signInTranslate.email[languageSlug]}</span>
            <button
              type="button"
              className="signUp_button"
              onClick={() => setSignUp(true)}
            >
              Зареєструватися
            </button>
          </div>
        )}

        <Formik
          initialValues={{
            login: "",
            password: "",
          }}
          validationSchema={Yup.object({
            password: Yup.string().required("Enter password!"),
            login: Yup.string().required("Enter login!"),
          })}
          onSubmit={async (body) => {
            const formData = new FormData();
            Object.entries(body).map(([key, value]) => {
              return formData.append(`${key}`, value.trim());
            });

            const req = await dispatch(signIn(formData));
            if (req.payload === 200) navigate(`/${languageSlug}/account`);
          }}
        >
          <div className="wrapper_signin">
            <span className="signin_title">
              {signInTranslate.entrance[languageSlug]}
            </span>
            {errorSignIn ? (
              <div
                style={{
                  margin: "0 auto",
                  width: "fit-content",
                  marginBottom: "10px",
                  color: "red",
                }}
              >
                {signInTranslate.error[languageSlug]}
              </div>
            ) : (
              ""
            )}
            <ErrorMessage name="login" className="error user" component="div" />
            <ErrorMessage
              name="password"
              className="error password"
              component="div"
            />
            <Form className="signin">
              <div>
                <label htmlFor="login">
                  {signInTranslate.login[languageSlug]}
                </label>
                <Field
                  className="user"
                  type="text"
                  placeholder="Enter login"
                  name="login"
                />
              </div>
              <div>
                <label htmlFor="password">Пароль</label>
                <Field
                  className="pass"
                  id="password"
                  type="password"
                  placeholder="Enter password"
                  name="password"
                />
              </div>
              <button type="submit">
                {signInTranslate.continue[languageSlug]}
              </button>
            </Form>
          </div>
        </Formik>
      </div>
    </div>
  );
};

export default SignIn;
