import React from "react";
import RenderMenuItem from "./components/RenderMenuItem";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedCategoryStore,
  setOpenCategory,
} from "../../../../store/slices/catalogSlice";
import "../../../../style/catalog.css";

const CatalogNav = ({ data_nav, toggleHamburger = () => {} }) => {
  const dispatch = useDispatch();

  const selectedCategory = useSelector(
    (state) => state.catalog.selectedCategory
  );
  const openCategory = useSelector((state) => state.catalog.openCategory);

  const onSelect = (catagory) => {
    dispatch(setSelectedCategoryStore(catagory));
  };

  const onToggleCategory = (id) => {
    dispatch(setOpenCategory(openCategory === id ? null : id));
  };

  return (
    <div className="catalog_sidenav">
      <div>
        <nav className="catalog_navbar">
          {data_nav &&
            data_nav.map((menuItem) => (
              <RenderMenuItem
                toggleHamburger={toggleHamburger}
                key={menuItem.id}
                menuItem={menuItem}
                onSelect={onSelect}
                selectedCategory={selectedCategory ? selectedCategory.id : null}
                openCategory={openCategory}
                onToggleCategory={onToggleCategory}
              />
            ))}
        </nav>
      </div>
    </div>
  );
};

export default CatalogNav;
