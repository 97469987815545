import React, { useEffect, useState } from "react";
import {
  getCategoriesCatalog,
  setOpenCategory,
  setSelectedCategoryStore,
  setDiscountCatalog,
  toggleOpenHamCatalog,
} from "../../store/slices/catalogSlice";
import { updateDiscountCounterpary } from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import CatalogHemlet from "./components/CatalogHemlet";
import useWindowDimensions from "../../useHook/useWindowDimensions";
import { findCategoryById } from "../../utils/findCategory";
import CatalogNav from "./components/nav/CatalogNav";
import CategoryCard from "./components/category/CategoryCard";
import Breadcrumbs from "../../utils/Breadcrumbs";
import Hamburger from "../../components/hamburger/Hamburger";
import { useParams } from "react-router-dom";
import { useLanguage } from "../../utils/LanguageContext";

const Catalog = () => {
  const dispatch = useDispatch();
  const { languageSlug } = useLanguage();
  const { category: categoryUrl } = useParams();
  const category = useSelector((state) => state.catalog.category);
  const { widthScreen } = useWindowDimensions();
  const user = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (user?.data?.id) {
      dispatch(updateDiscountCounterpary(user.data.id));
      dispatch(setDiscountCatalog(user?.discount));
    }
  }, [user, dispatch]);

  const firstRequest = async () => {
    const action = await dispatch(getCategoriesCatalog());
    const data = action.payload;
    const found = findCategoryById({
      id: parseInt(categoryUrl),
      data,
    });

    if (categoryUrl && found) {
      dispatch(setSelectedCategoryStore(found));
      dispatch(setOpenCategory(found.id));
    } else {
      dispatch(setSelectedCategoryStore(null));
      dispatch(setOpenCategory(null));
    }

    setIsLoading(false);
  };

  useEffect(() => {
    firstRequest();
  }, [categoryUrl, dispatch]);

  const selectedCategory = useSelector(
    (state) => state.catalog.selectedCategory
  );

  const data_card =
    selectedCategory && !selectedCategory.parentSub_id && category
      ? selectedCategory.subMenu
      : category;

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  useEffect(() => {
    if (categoryUrl && selectedCategory) {
      setBreadcrumbs([
        { title: "Каталог", to: "catalog" },
        { title: selectedCategory[`name_${languageSlug}`], to: categoryUrl },
      ]);
    } else {
      setBreadcrumbs([{ title: "Каталог", to: "catalog" }]);
      dispatch(setSelectedCategoryStore(null));
      dispatch(setOpenCategory(null));
    }
  }, [languageSlug, selectedCategory, categoryUrl, dispatch]);

  const hamCatalog = useSelector((state) => state.catalog.hamCatalog);

  return (
    <>
      <CatalogHemlet lang={languageSlug} />
      <div style={{ display: "flex", flex: "1" }}>
        {widthScreen > 900 && <CatalogNav data_nav={category} />}
        <div className="product_main">
          <Breadcrumbs params={breadcrumbs} />
          {widthScreen < 900 && (
            <div className="catalogMobileButton-wrapper">
              <Hamburger
                open={hamCatalog}
                Icon={
                  <button className="catalogMobileButton_catalogButton">
                    Каталог
                  </button>
                }
                Сontent={
                  <CatalogNav
                    data_nav={category}
                    toggleHamburger={() => dispatch(toggleOpenHamCatalog())}
                  />
                }
                title={"Каталог"}
                toggle={toggleOpenHamCatalog}
              />
            </div>
          )}
          <div className="catalog_card-container">
            {data_card &&
              !isLoading &&
              data_card.map((item) => (
                <CategoryCard lang={languageSlug} key={item.id} item={item} />
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Catalog;
