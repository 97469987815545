import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../utils/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getProductById,
  getCategoryById,
} from "../../store/slices/catalogSlice";
import { useLanguage } from "../../utils/LanguageContext";
import ProductViewer from "./components/ProductViewer";
import ProductDetailsMini from "./components/ProductDetailsMini";
import ProductHelmet from "./components/ProductHelmet";
// for dev test data
import { containsLoremFlickr } from "../../utils/checkImg";
import { IMG_URL } from "../../api";
import { productPageTranslate } from "../../utils/translate";
import "../../style/product.css";
const ProductPage = () => {
  const dispatch = useDispatch();
  const { languageSlug } = useLanguage();
  const { product: productIdFromUrl, subCategory, category } = useParams();
  const product = useSelector((state) => state.catalog.product);

  const images = [
    product?.img_path,
    ...(product?.Attributes?.filter((item) => item.type === "image")?.map(
      (item) => item?.product_has_attributes?.value_ua
    ) || []),
  ];

  const productDetails =
    product?.Attributes?.filter((item) => item.type === "addition")?.map(
      (item) => ({
        name_ua: item?.title_ua,
        name_ru: item?.title_ru,
        value_ua: item?.product_has_attributes?.value_ua,
        value_ru: item?.product_has_attributes?.value_ru,
        id: item?.id,
      })
    ) || [];

  const productDetailsMini =
    product?.Attributes?.filter((item) => item.type === "main")?.map(
      (item) => ({
        name_ua: item.title_ua,
        name_ru: item.title_ru,
        value_ua: item.product_has_attributes.value_ua,
        value_ru: item.product_has_attributes.value_ru,
        id: item.id,
      })
    ) || [];

  useEffect(() => {
    dispatch(getProductById(productIdFromUrl));
  }, [productIdFromUrl]);

  // breadcrumbs
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  useEffect(() => {
    const fetchBreadcrumbs = async () => {
      const cat = await dispatch(getCategoryById(category));
      const sub = await dispatch(getCategoryById(subCategory));

      if (subCategory && category) {
        setBreadcrumbs([
          { title: "Каталог", to: "catalog" },
          { title: cat.payload[`name_${languageSlug}`], to: category },
          { title: sub.payload[`name_${languageSlug}`], to: subCategory },
          { title: product[`name_${languageSlug}`], to: product.id },
        ]);
      }
    };

    fetchBreadcrumbs();
  }, [languageSlug, product]);

  return (
    <>
      {product[`name_${languageSlug}`] && (
        <ProductHelmet
          lang={languageSlug}
          productName={product[`name_${languageSlug}`]}
        />
      )}

      <div className="productPage">
        <Breadcrumbs params={breadcrumbs} />
        <div className="productPage_titleMain">
          <span>{product[`name_${languageSlug}`]}</span>
          <span>Aртикул: {product.vendor_code}</span>
        </div>
        <div className="productPage_det_wrapper">
          <ProductViewer
            images={images.map((item) =>
              containsLoremFlickr(item) ? item : IMG_URL + item
            )}
          />
          <ProductDetailsMini
            product={product}
            details={productDetailsMini}
            lang={languageSlug}
            translate={productPageTranslate}
          />
        </div>

        <div className="product-details">
          <div className="product-details_title-wrapper">
            <span>{productPageTranslate.description[languageSlug]}</span>
          </div>
          <div className="product-details_cards">
            {productDetails &&
              productDetails.map((item) => (
                <div key={item.id} className="product-details_cards_content">
                  <span>{item[`name_${languageSlug}`]}:</span>
                  <span>{item[`value_${languageSlug}`]}</span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductPage;
