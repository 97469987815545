import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { findProduct } from "../../../store/slices/catalogSlice";
import { NavLink } from "react-router-dom";
import { useDebounce } from "../../../useHook/useDebounce";
import { IMG_URL } from "../../../api";
// for dev test data
import { containsLoremFlickr } from "../../../utils/checkImg";

const FindProudct = ({ lang }) => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.catalog.findProduct);
  const ref = useRef(null);
  const [searchText, setSearchText] = useState("");
  const debouncedSearchTerm = useDebounce(searchText, 200);
  useEffect(() => {
    if (searchText) {
      dispatch(findProduct({ lang, searchText }));
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setSearchText("");
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="searchInput" ref={ref}>
      <div className="searchInput-wrapper">
        <input
          id="search"
          type="text"
          placeholder={lang === "ru" ? "Поиск..." : "Пошук..."}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>

      {searchText && (
        <div className="searchInput_content-wrapprt">
          <div className="searchInput_content">
            {products && products.length > 0 ? (
              products.map((item) => (
                <NavLink
                  to={`/${lang}/catalog/${item?.Categories[0]?.parentSub_id}/${item?.Categories[0]?.id}/${item?.id}`}
                  onClick={() => setSearchText("")}
                  className="searchInput_content_product"
                  key={item.id}
                >
                  <img
                    className="searchInput_content_img"
                    // src={IMG_URL + item.img_path}
                    src={
                      containsLoremFlickr(item.img_path)
                        ? item.img_path
                        : IMG_URL + item.img_path
                    }
                    alt=""
                  />
                  <span className="searchInput_content_title">
                    {item[`name_${lang}`]}
                  </span>
                </NavLink>
              ))
            ) : (
              <div className="searchInput_content_product">
                <span className="searchInput_content_notProduct">
                  {lang === "ru" ? "Не найдено" : "Не знайдено"}
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FindProudct;
