import React from "react";
import { useNavigate } from "react-router-dom";
import { basketTranslate } from "../../../utils/translate";
import BasketCard from "./BasketCard";
const BasketContent = ({ toggleOpen = () => {}, data, total, lang }) => {
  const navigate = useNavigate();

  const totalPrice =
    data.length > 0 &&
    data.reduce((total, { product, quantity }) => {
      return total + product.price_in_uah * quantity;
    }, 0);

  return (
    <>
      <div className="basket-modal_cards-wrapper">
        {data &&
          data.map((item) => (
            <BasketCard
              lang={lang}
              key={item.product.id}
              product={item.product}
              quantity={item.quantity}
            />
          ))}
      </div>
      <div className="basket-modal_bottom">
        <div className="basket-modal_bottom_price-wrapper">
          <span>{basketTranslate.pay[lang]}</span>
          <span>{totalPrice} грн.</span>
        </div>
        <button
          onClick={() => {
            if (total > 0) {
              navigate(`/${lang}/order`);
              toggleOpen();
            }
          }}
          className="basket-modal_bottom_button"
        >
          {basketTranslate.order[lang]}
        </button>
      </div>
    </>
  );
};

export default BasketContent;
