import React from "react";
import { Helmet } from "react-helmet-async";
const FilterWithProductHelmet = ({ lang, breadcrumbs }) => {
  const metaTags = (language) => {
    switch (language) {
      case "ru":
        return (
          <>
            <title>
              {breadcrumbs &&
                breadcrumbs.length === 3 &&
                `Каталог Дом фурнитуры : ${breadcrumbs[1]?.title} : ${breadcrumbs[2]?.title}`}
            </title>
            {/* <meta name="description" content="" /> */}
            <meta
              name="keywords"
              content={
                breadcrumbs &&
                breadcrumbs.length === 3 &&
                `${breadcrumbs[1]?.title}, ${breadcrumbs[2]?.title}`
              }
            />
          </>
        );
      case "ua":
        return (
          <>
            <title>
              {breadcrumbs &&
                breadcrumbs.length === 3 &&
                `Каталог Дім фурнітури : ${breadcrumbs[1]?.title} : ${breadcrumbs[2]?.title}`}
            </title>
            {/* <meta name="description" content="" /> */}
            <meta
              name="keywords"
              content={
                breadcrumbs &&
                breadcrumbs.length === 3 &&
                `${breadcrumbs[1]?.title}, ${breadcrumbs[2]?.title}`
              }
            />
          </>
        );
    }
  };
  return <Helmet>{metaTags(lang)}</Helmet>;
};

export default FilterWithProductHelmet;
