import React, { useEffect } from "react";
import { useLanguage } from "../../utils/LanguageContext";
import { useNavigate } from "react-router-dom";
import { logout } from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import AccountNav from "./components/AccountNav";
import Contact from "./components/Contact";
import AccountPassword from "./components/AccountPassword";
import AccountOrders from "./components/AccountOrders";
import "../../style/account.css";
import { accountTranslate } from "../../utils/translate";
const Account = () => {
  const { languageSlug } = useLanguage();
  const dispath = useDispatch();
  const navigate = useNavigate();
  const { activeNav, data, access } = useSelector((state) => state.user);

  const page = (active) => {
    switch (active) {
      case "contact":
        return <Contact user_data={data} lang={languageSlug} />;
      case "password":
        return <AccountPassword user_data={data} lang={languageSlug} />;
      case "purchase":
        return <AccountOrders lang={languageSlug} />;
    }
  };

  useEffect(() => {
    if (!access) {
      navigate(`/${languageSlug}/`);
    }
  });
  return (
    <div className="accountPage">
      <span className="accountPage_title">
        {accountTranslate.hello[languageSlug]}, {data?.name}!
      </span>
      <AccountNav lang={languageSlug} active={activeNav} />
      {page(activeNav)}

      <div className="accountPage_exitButton">
        <button onClick={() => dispath(logout())}>Выход</button>
      </div>
    </div>
  );
};

export default Account;
