import React, { useState } from "react";
import QuantityInput from "../../../components/inputs/QuantityInput";
import AddButton from "../../../components/buttons/AddButton";
import { productTranslate } from "../../../utils/translate";
const ProductDetailsMini = ({ details, lang, product }) => {
  const [quantity, setQuantity] = useState(1);

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  return (
    <div className="product-details-mini">
      <div className="product-details-mini_price">
        <div className="product-details-mini_amount">
          {product.price_in_uah}
        </div>
        <div className="product-details-mini_price-currency">
          грн/{product?.measured}
        </div>
      </div>
      {product?.available && (
        <div className="product-details-mini_aviable">
          <div className="product-details-mini_aviable_dot"></div>
          <span>{productTranslate.available[lang]}</span>
        </div>
      )}
      <div className="product-details-mini_characteristics">
        {details &&
          details.map((item) => (
            <div key={item.id}>
              <span>{item[`name_${lang}`]} </span>
              {item[`value_${lang}`]}
            </div>
          ))}
      </div>
      <div className="product-details-mini_buttonWrapper">
        <QuantityInput
          quantity={quantity}
          handleQuantityChange={handleQuantityChange}
          lang={lang}
        />

        <AddButton product={product} quantity={quantity} lang={lang} />
      </div>
    </div>
  );
};

export default ProductDetailsMini;
