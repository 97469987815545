import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedCategoryStore,
  setOpenCategory,
} from "../../../../store/slices/catalogSlice";
import { IMG_URL } from "../../../../api";
// for dev test data
import { containsLoremFlickr } from "../../../../utils/checkImg";

const CategoryCard = ({ item, lang }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigateTo = item.parentSub_id
    ? `/${lang}/catalog/${item.parentSub_id}/${item.id}`
    : `/${lang}/catalog/${item.id}`;

  const onSelect = () => {
    dispatch(setSelectedCategoryStore(item));
    if (!item.parentSub_id) dispatch(setOpenCategory(item.id));
    navigate(navigateTo);
  };

  return (
    <div className="categoryCard" onClick={() => onSelect()}>
      <h2 className="categoryCard_title">{item[`name_${lang}`]}</h2>
      <img
        className="categoryCard_img"
        // src={IMG_URL + item.img_path}
        src={
          containsLoremFlickr(item.img_path)
            ? item.img_path
            : IMG_URL + item.img_path
        }
        alt=""
      />
    </div>
  );
};

export default CategoryCard;
