import React from "react";
import QuantityInput from "../../inputs/QuantityInput";
import del_icon from "../../../style/images/del_icon.svg";
import { useDispatch } from "react-redux";
import {
  delItemBasket,
  updateBasketItemQuantity,
} from "../../../store/slices/orderSlice";
import { IMG_URL } from "../../../api";
// for dev test data
import { containsLoremFlickr } from "../../../utils/checkImg";

const BasketCard = ({ product, quantity, lang, style = false }) => {
  const dispatch = useDispatch();

  const handleQuantityChange = (e) => {
    dispatch(
      updateBasketItemQuantity({
        product_id: product.id,
        quantity: e.target.value,
      })
    );
  };

  return (
    <div className="basket-modal_card" style={style ? style : {}}>
      <img
        src={
          containsLoremFlickr(product.img_path)
            ? product.img_path
            : IMG_URL + product.img_path
        }
        // src={IMG_URL + product.img_path}
        alt="product image"
        className="basket-modal_card_img"
      />
      <div className="basket-modal_card_content">
        <span className="basket-modal_card_name">
          {product[`name_${lang}`]}
        </span>
        <div className="basket-modal_card_promo">
          <div className="basket-modal_card_promo_left">
            <QuantityInput
              quantity={quantity}
              lang={lang}
              handleQuantityChange={handleQuantityChange}
            />
            <img
              src={del_icon}
              alt="del icon"
              onClick={() => dispatch(delItemBasket(product.id))}
            />
          </div>

          <div className="basket-modal_card_promo_right">
            <span>{product.price_in_uah}</span>
            <span>грн/{product?.measured}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasketCard;
