import React from "react";
import { Helmet } from "react-helmet-async";
const CatalogHemlet = ({ lang }) => {
  const metaTags = (language) => {
    switch (language) {
      case "ua":
        return (
          <>
            <title>Дім фурнітури - каталог фурнітури в Одесі</title>
            <meta
              name="description"
              content="Вигідні ціни у магазині Дім Фурнітури на товари каталогу. Кольори, форми, оздоблення. Для всієї вашої мебелі: для кухні, шаф та іншого. Найбільший вибір меблевої фурнітури. Купити в Одесі."
            />
          </>
        );
      case "ru":
        return (
          <>
            <title>Дом фурнитуры - каталог фурнитуры в Одессе</title>
            <meta
              name="description"
              content="Выгодные цены в магазине Дом Фурнитуры на товары каталога. Цвета, формы, украшения. Для всей вашей мебели: для кухни, шкафов и прочего. Самый большой выбор мебельной фурнитуры. Купить в Одессе."
            />
          </>
        );
    }
  };
  return <Helmet>{metaTags(lang)}</Helmet>;
};

export default CatalogHemlet;
