import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import $api from "../../api";

export const getCategoriesCatalog = createAsyncThunk(
  "catalog/getCategoriesCatalog",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api.get("/category");

      dispatch(setCategory(req.data.data));

      return req.data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getCategoryById = createAsyncThunk(
  "catalog/getProductById",
  async (id, { rejectWithValue }) => {
    try {
      const req = await $api(`/category/${id}`);

      return req.data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getFiltersCategoryById = createAsyncThunk(
  "catalog/getProductById",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api(`/category_filter/${id}`);
      dispatch(setFilters(req.data.data));
      dispatch(setPriceInitialRange(req.data.price));
      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getProductsByCatagory = createAsyncThunk(
  "catalog/getProductsByCatagory",
  async (
    { category, offset = 0, limit = 25, search = "" },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const req = await $api(
        `/product/${category}?offset=${offset}&limit=${limit}${search}`
      );

      dispatch(setProducts(req.data.data.products));
      dispatch(setTotalProducts(req.data.data.totalProducts));
      // dispatch(setPriceRange(req.data.data.price));
      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getProductsByCatagoryForExel = createAsyncThunk(
  "catalog/getProductsByCatagoryForExel",
  async (
    { category, offset = 0, limit = 25, search = "" },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const req = await $api(
        `/product/${category}?offset=${offset}&limit=${limit}${search}`
      );

      return req.data.data.products;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const findProduct = createAsyncThunk(
  "catalog/findProduct",
  async ({ lang, searchText }, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api.get(
        `/find_product_shop?lang=${lang}&searchText=${searchText}`
      );

      dispatch(setFindProduct(req.data.data));
      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getProductById = createAsyncThunk(
  "catalog/getProductById",
  async (id, { rejectWithValue, dispatch, getState }) => {
    try {
      const req = await $api(`/product_by_id/${id}`);
      const state = getState();
      const discount = state.user.discount;
      const aroundPriceUAH = [req.data.data].map(
        ({ price_in_uah, ...rest }) => ({
          price_in_uah: (price_in_uah * (1 - discount / 100)).toFixed(2),
          ...rest,
        })
      );
      dispatch(setOneProduct(aroundPriceUAH[0]));
      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const catalog = createSlice({
  name: "catalog",
  initialState: {
    category: [],
    filters: [],
    filtertsCheckedNames: [],
    priceInitialRange: { min: 0, max: 0 },
    priceRange: { min: 0, max: 0 },
    product: [],
    selectedCategory: null,
    openCategory: [],
    findProduct: [],
    products: [],
    totalProducts: null,
    error: null,
    loading: false,
    excel: null,
    discount: 0,
    hamCatalog: false,
    hamFilter: false,
  },
  reducers: {
    setCategory(state, { payload }) {
      state.category = [...payload];
    },
    setFilters(state, { payload }) {
      state.filters = [...payload];
    },
    setDiscountCatalog(state, { payload }) {
      state.discount = payload;
    },
    setPriceInitialRange(state, { payload }) {
      state.priceInitialRange = {
        min: payload.minValue,
        max: payload.maxValue,
      };
    },
    setPriceRange(state, { payload }) {
      state.priceRange = {
        min: payload.min,
        max: payload.max,
      };
    },
    setFiltertsCheckedNames(state, { payload }) {
      state.filtertsCheckedNames = payload;
    },
    setProducts(state, { payload }) {
      state.products = payload.map(({ price_in_uah, ...rest }) => ({
        // price_in_uah: price_in_uah.toFixed(2),
        price_in_uah: (price_in_uah * (1 - state.discount / 100)).toFixed(2),
        ...rest,
      }));
    },
    setFindProduct(state, { payload }) {
      state.findProduct = payload;
    },
    setTotalProducts(state, action) {
      state.totalProducts = action.payload;
    },

    setOneProduct(state, action) {
      state.product = action.payload;
    },

    setOpenCategory(state, { payload }) {
      state.openCategory = payload;
    },
    setSelectedCategoryStore(state, { payload }) {
      state.selectedCategory = payload;
    },

    toggleOpenHamCatalog(state) {
      state.hamCatalog = !state.hamCatalog;
    },
    toggleOpenHamFilter(state) {
      state.hamFilter = !state.hamFilter;
    },
  },
});

export const {
  setCategory,
  setOpenCategory,
  setSelectedCategoryStore,
  setProducts,
  setOneProduct,
  setTotalProducts,
  setFilters,
  setFiltertsCheckedNames,
  setPriceInitialRange,
  setPriceRange,
  setFindProduct,
  toggleOpenHamCatalog,
  toggleOpenHamFilter,
  setUSD,
  setDiscountCatalog,
} = catalog.actions;

export default catalog.reducer;
