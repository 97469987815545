import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  createMigrate,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import loggerMiddleware from "redux-logger";
import user from "./slices/userSlice";
import catalog from "./slices/catalogSlice";
import order from "./slices/orderSlice";

const combinedReducer = combineReducers({
  user,
  catalog,
  order,
});

const migrations = {
  3: (state) => {
    return {
      ...state,
      user: {
        ...state.user,
        discount: 0,
      },
      catalog: {
        ...state.catalog,
        discount: 0,
      },
    };
  },
};
const persistConfig = {
  key: "manag",
  storage,
  version: 3,
  migrate: createMigrate(migrations, { debug: false }),
  blacklist: ["data"],
};

const persistedReducer = persistReducer(persistConfig, combinedReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  // .concat(loggerMiddleware),
});

export const persistor = persistStore(store);
export default store;
