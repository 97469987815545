import React from "react";
import { saveExcel } from "./saveExcel";
import { useDispatch } from "react-redux";
import { getProductsByCatagoryForExel } from "../../store/slices/catalogSlice";
const Exel = ({ title, subCategory }) => {
  const dispatch = useDispatch();

  const save = async () => {
    const data = await dispatch(
      getProductsByCatagoryForExel({
        category: subCategory,
        limit: 9999,
        offset: 0,
      })
    );

    saveExcel({ data: data.payload, title });
  };
  return (
    <button
      style={{
        padding: "6px 20px",
        color: "#008947",
        border: "none",
        borderRadius: "4px",
        background: "#fff",
        border: "1px solid #008947",
      }}
      onClick={() => save()}
    >
      Exel
    </button>
  );
};

export default Exel;
