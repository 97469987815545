import React from "react";
import { useDispatch } from "react-redux";
import { addBasketData, toggleOpenBasket } from "../../store/slices/orderSlice";
import { productPageTranslate } from "../../utils/translate";
const AddButton = ({ product, quantity = 1, lang }) => {
  const dispatch = useDispatch();
  const addData = () => {
    dispatch(addBasketData({ product, quantity }));
    dispatch(toggleOpenBasket());
  };
  // console.log(productPageTranslate.basket[lang]);
  return (
    <button className="productCard_bottom_button" onClick={addData}>
      {productPageTranslate.basket[lang]}
    </button>
  );
};

export default AddButton;
