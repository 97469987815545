import React from "react";
import "../../style/inputs.css";
const QuantityInput = ({ quantity, handleQuantityChange, lang = "ua" }) => {
  return (
    <div className="product-details-input_quantity">
      <label htmlFor="quantity" style={lang === "ru" ? { left: "10px" } : {}}>
        {lang === "ua" ? "Кількість" : "Количество"}
      </label>
      <input
        type="number"
        id="quantity"
        min="1"
        value={quantity}
        onChange={handleQuantityChange}
      />
    </div>
  );
};

export default QuantityInput;
