import React from "react";
import { useSelector } from "react-redux";
import BasketCard from "../../../components/basket/components/BasketCard";
import { orderBasketTranslate } from "../../../utils/translate";
const OrderBasket = ({ lang }) => {
  const data = useSelector((state) => state.order.basket);
  const totalPrice =
    data.length > 0 &&
    data.reduce((total, { product, quantity }) => {
      return total + product.price_in_uah * quantity;
    }, 0);
  return (
    <div className="orderBasket">
      <span className="orderBasket_title">
        {orderBasketTranslate.basket[lang]}
      </span>

      <div className="orderBasket_cards">
        {data &&
          data.map((item) => (
            <BasketCard
              lang={lang}
              key={item.product.id}
              product={item.product}
              quantity={item.quantity}
              style={{
                border: "none",
                borderBottom: "1px solid #456D64",
                borderRadius: "0",
                width: "365px",
                margin: "0 auto",
              }}
            />
          ))}
      </div>

      <div className="orderBasket_count">
        <span>{orderBasketTranslate.price[lang]}:</span>
        <span>{totalPrice}₴</span>
      </div>
    </div>
  );
};

export default OrderBasket;
