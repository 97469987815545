import React, { useState } from "react";
import { ReactComponent as BugerIcon } from "../../../style/images/burger_icon.svg";

const FilterSection = ({ title, options, onFilterChange }) => {
  const [open, setOpen] = useState(false);

  const handleChange = (e) => {
    const { id, checked } = e.target;

    onFilterChange(id, checked);
  };

  // console.log(options);
  return (
    <div className={`filter-section ${open ? "active" : "close"} `}>
      <div
        className="filter-section_title-wrapper"
        onClick={() => setOpen(!open)}
      >
        <h3>{title}</h3>
        <BugerIcon />
      </div>

      {open &&
        options &&
        options.map((option) => (
          <div key={option.id} className="filter-section_sub">
            <input
              className="chb chb-3"
              type="checkbox"
              id={option.id}
              name={option.name}
              checked={option.checked}
              onChange={handleChange}
            />
            <label htmlFor={option.id}>{option.name}</label>
          </div>
        ))}
    </div>
  );
};

export default FilterSection;
