import React from "react";
import logo_mobile from "../../style/images/logo_mobile.png";
import logo_with_year from "../../style/images/logo_with_year.png";
import years_logo from "../../style/images/20_years.png";
import { ReactComponent as User_icon } from "../../style/images/user_icon.svg";
import BasketModal from "../basket/BasketModal";
import FindProudct from "./components/FindProudct";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useLanguage } from "../../utils/LanguageContext";
import useWindowDimensions from "../../useHook/useWindowDimensions";

import "../../style/header.css";
const Header = () => {
  const user = useSelector((state) => state.user.access);
  const { languageSlug } = useLanguage();
  const { widthScreen } = useWindowDimensions();

  return (
    <header className="wrapper-header">
      {widthScreen < 900 && (
        <img
          src={years_logo}
          className="wrapper-header_years_mibile"
          alt="logo"
        />
      )}

      <NavLink to={`/${languageSlug}/`}>
        {widthScreen > 900 ? (
          <img
            src={logo_with_year}
            className="wrapper-header_logo"
            alt="logo"
          />
        ) : (
          <img
            src={logo_mobile}
            className="wrapper-header_logo_mibile"
            alt="logo"
          />
        )}
      </NavLink>

      <FindProudct lang={languageSlug} />

      <div className="header_icon-wrapper">
        <NavLink
          to={user ? `/${languageSlug}/account` : `/${languageSlug}/signIn`}
          className="header_icon_user"
        >
          <User_icon alt="user icon" />
        </NavLink>

        <BasketModal />
      </div>
    </header>
  );
};

export default Header;
