import React, { useEffect } from "react";
import user_icon from "../../../style/images/account_user.png";
import purchase_icon from "../../../style/images/basket_icon.svg";
import pass_icon from "../../../style/images/pass.png";
import { setActiveNav } from "../../../store/slices/userSlice";
import { useDispatch } from "react-redux";
const nav_data = [
  {
    title: {
      ru: "Контактная инф;",
      ua: "Контактна інф.",
    },
    id: "contact",
    img: user_icon,
  },
  {
    title: {
      ru: "Покупки",
      ua: "Покупки",
    },
    id: "purchase",
    img: purchase_icon,
  },
  {
    title: {
      ru: "Пароль",
      ua: "Пароль",
    },
    id: "password",
    img: pass_icon,
  },
];

const AccountNav = ({ lang, active }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!active) {
      dispatch(setActiveNav(nav_data[0].id));
    }
  }, []);
  return (
    <div className="account_nav">
      {nav_data.map((item) => (
        <div
          className={`account_nav_item ${active === item.id ? "active" : ""}`}
          key={item.id}
          onClick={() => dispatch(setActiveNav(item.id))}
        >
          <img src={item.img} alt="" />
          <span>{item.title[lang]}</span>
        </div>
      ))}
    </div>
  );
};

export default AccountNav;
