import React from "react";
import { Helmet } from "react-helmet-async";
const HomeHelmet = ({ lang }) => {
  const metaTags = (language) => {
    switch (language) {
      case "ru":
        return (
          <>
            <title>
              Дом фурнитуры - Фурнитура для мебели в Одессе: ручки, петли,
              направляющие, крепеж
            </title>
            <meta
              name="description"
              content={`Добро пожаловать в Дом фурнитуры! Мы предлагаем широкий ассортимент мебели в Одессе: ручки, петли, направляющие, крепления и многое другое. Высокое качество и доступные цены. Посетите наш магазин по ул. Ефимова, 43 и убедитесь сами!`}
            />
            <meta
              name="keywords"
              content={`мебель Одесса, фурнитура для мебели, мебельная фурнитура Одесса, купить фурнитуру для мебели, ручки для мебели, петли для мебели, направляющие для мебели, крепеж для мебели, мебельные аксессуары Одесса, высококачественная фурнитура, доступные цены на фурнитуру, магазин фурнитуры Одесса, мебельная фурнитура недорого, мебельная фурнитура купить, магазин мебели Одесса`}
            />
          </>
        );
      case "ua":
        return (
          <>
            <title>
              Дім фурнітури - Фурнітура для меблів в Одесі: ручки, петлі,
              направляючі, кріплення
            </title>
            <meta
              name="description"
              content={`Ласкаво просимо до Дім фурнітури! Ми пропонуємо широкий асортимент фурнітури для меблів в Одесі: ручки, петлі, направляючі, кріплення та багато іншого. Висока якість та доступні ціни. Відвідайте наш магазин на вул. Єфімова, 43 і переконайтеся самі!`}
            />
            <meta
              name="keywords"
              content={`меблі Одеса, фурнітура для меблів, меблева фурнітура Одеса, купити фурнітуру для меблів, ручки для меблів, петлі для меблів, направляючі для меблів, кріплення для меблів, меблеві аксесуари Одеса, високоякісна фурнітура, доступні ціни на фурнітуру, магазин фурнітури Одеса, меблева фурнітура недорого, меблева фурнітура купити, магазин меблів Одеса,`}
            />
          </>
        );
    }
  };
  return <Helmet>{metaTags(lang)}</Helmet>;
};

export default HomeHelmet;
