import React, { useState, useEffect } from "react";
import VerticalSlider from "../../../useHook/slider/VerticalSlider";
import ProductViewerModal from "./ProductViewerModal";
import HorizontalSlider from "../../../useHook/slider/HorizontalSlider";
import useWindowDimensions from "../../../useHook/useWindowDimensions";
import { IMG_URL } from "../../../api";
const ProductViewer = ({ images }) => {
    const {widthScreen} = useWindowDimensions();
  const [selectedImage, setSelectedImage] = useState();
  const [modal, setModal] = useState(false);
  useEffect(() => {
    setSelectedImage(images[0]);
  }, [images]);
  return (
    <div className="product-viewer">
      <div className="product-viewer_thumbnail-list">
          {widthScreen > 900 ? (
          <VerticalSlider
            images={images}
            selectedIndex={selectedImage}
            setSelectedIndex={setSelectedImage}
          />
        ) : (
          <HorizontalSlider
            images={images}
            selectedIndex={selectedImage}
            setSelectedIndex={setSelectedImage}
          />
        )}
      </div>

      <div className="product-viewer_main-image">
        <img
          src={selectedImage}
          onClick={() => setModal(true)}
          alt="Selected"
        />
      </div>
      <ProductViewerModal
        visible={modal}
        onClose={() => setModal(false)}
        images={images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Thumbnail ${index + 1}`}
            className={`product-viewer_thumbnail `}
          />
        ))}
      />
    </div>
  );
};

export default ProductViewer;
