import Hamburger from "../hamburger/Hamburger";
import { useSelector, useDispatch } from "react-redux";
import { toggleOpenBasket } from "../../store/slices/orderSlice";
import BasketContent from "./components/BasketContent";
import { useLanguage } from "../../utils/LanguageContext";
import "../../style/basket.css";
import basket_icon from "../../style/images/basket_icon.svg";
import { basketTranslate } from "../../utils/translate";
const BasketModal = () => {
  const dispatch = useDispatch();
  const { languageSlug } = useLanguage();
  const total = useSelector((state) => state.order.basket).length;
  const open = useSelector((state) => state.order.basketOpen);
  const data = useSelector((state) => state.order.basket);

  return (
    <Hamburger
      open={open}
      Icon={
        <>
          <span className="header_basket_total">{total}</span>
          <img src={basket_icon} alt="basket_icon" />
        </>
      }
      toggle={toggleOpenBasket}
      Сontent={
        <BasketContent
          toggleOpen={() => dispatch(toggleOpenBasket())}
          data={data}
          total={total}
          lang={languageSlug}
        />
      }
      title={`${basketTranslate.basket[languageSlug]} (${total})`}
    />
  );
};

export default BasketModal;
