import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddButton from "../../../components/buttons/AddButton";
import { IMG_URL } from "../../../api";
import { containsLoremFlickr } from "../../../utils/checkImg";
import new_icon from "../../../style/images/new_icon.png";
import hit_icon from "../../../style/images/hit_icon.png";
import sale_icon from "../../../style/images/sale_icon.png";
const ProductCard = ({ product, lang }) => {
  const navigate = useNavigate();
  const { category, subCategory } = useParams();
  const productDetails = product?.Attributes?.filter(
    (item) => item.type === "main"
  ).map((item) => ({
    name_ua: item.title_ua,
    name_ru: item.title_ru,
    value_ua: item.product_has_attributes.value_ua,
    value_ru: item.product_has_attributes.value_ru,
    id: item.id,
  }));

  return (
    <div className="productCard">
      <div className="productCard_addAttr">
        {product?.new_product && <img src={new_icon} alt="" />}
        {product?.top_product && <img src={hit_icon} alt="" />}
        {product?.sale && <img src={sale_icon} alt="" />}
      </div>
      <img
        className="productCard_img"
        // src={IMG_URL + product.img_path}
        src={
          containsLoremFlickr(product.img_path)
            ? product.img_path
            : IMG_URL + product.img_path
        }
        alt="product image"
        onClick={() =>
          navigate(`/${lang}/catalog/${category}/${subCategory}/${product.id}`)
        }
      />

      <h1
        className="productCard_title"
        onClick={() =>
          navigate(`/${lang}/catalog/${category}/${subCategory}/${product.id}`)
        }
      >
        {product[`name_${lang}`]}
      </h1>

      <span className="productCard_article">
        Артикул: {product.vendor_code}
      </span>

      <div className="productCard_characteristics">
        {productDetails &&
          productDetails.map((item) => (
            <div key={item.id}>
              <span>{item[`name_${lang}`]}:</span>
              <span>{item[`value_${lang}`]}</span>
            </div>
          ))}
      </div>

      <div className="productCard_bottom">
        <div className="productCard_bottom_price">
          <div className="productCard_bottom_price_amount">
            {product?.sale && (
              <div className="productCard_bottom_price_amount_sale">
                {product?.old_price_in_uah}
              </div>
            )}
            <span style={product?.sale ? { color: "red" } : {}}>
              {product?.price_in_uah}
            </span>
          </div>
          <div className="productCard_bottom_price_currency">
            грн/{product?.measured}
          </div>
        </div>
        <AddButton product={product} lang={lang} />
      </div>
    </div>
  );
};

export default ProductCard;
