import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCounterpary } from "../../../store/slices/userSlice";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import { accountTranslate } from "../../../utils/translate";
import Spinner from "../../../utils/spiner/Spinner";
const Contact = ({ user_data, lang }) => {
  const dispatch = useDispatch();
  const { error: errorSignIn, loading } = useSelector((state) => state.user);
  return (
    <Formik
      initialValues={{
        name: user_data ? user_data.name : "",
        login: user_data ? user_data.login : "",
        phone: user_data ? user_data.phone : "",
        email: user_data ? user_data.email : "",
      }}
      validationSchema={Yup.object({
        name: Yup.string().required("Enter password!"),
        login: Yup.string().required("Enter login!"),
        email: Yup.string()
          .trim()
          .required("Enter email")
          .email("Некорректный email"),
        phone: Yup.string().required("Enter phone!"),
      })}
      onSubmit={async (body) => {
        const formData = new FormData();
        Object.entries(body).map(([key, value]) => {
          return formData.append(`${key}`, value.trim());
        });

        const req = await dispatch(updateCounterpary(formData));
      }}
    >
      {({ errors, touched, handleChange, handleBlur }) => (
        <div className="accountContact">
          {/* {errorSignIn ? <div>Wrong password or login</div> : ""} */}
          {errorSignIn ? <div>Wrong password or login</div> : ""}
          {loading && <Spinner />}
          <ErrorMessage name="name" className="error user" component="div" />
          <ErrorMessage
            name="login"
            className="error password"
            component="div"
          />
          <ErrorMessage
            name="phone"
            className="error password"
            component="div"
          />
          <Form>
            <div className="accountContact_item-wrapper">
              <div className="accountContact_item">
                <label htmlFor="name">{accountTranslate.name[lang]}:</label>
                <Field
                  className=""
                  type="text"
                  placeholder="Enter name"
                  name="name"
                />
              </div>

              <div className="accountContact_item">
                <label htmlFor="login">{accountTranslate.login[lang]}:</label>
                <Field
                  className="pass"
                  id="login"
                  type="text"
                  placeholder="Enter login"
                  name="login"
                />
              </div>

              <div className="accountContact_item">
                <label htmlFor="phone">{accountTranslate.phone[lang]}:</label>
                <Field name="phone">
                  {({ field }) => (
                    <InputMask
                      {...field}
                      mask="+38(099) 999-9999"
                      id="phone"
                      placeholder="Номер телефону"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className=""
                    />
                  )}
                </Field>
              </div>
            </div>

            <div className="accountContact_item_lastWrapper">
              <div className="accountContact_item">
                <label htmlFor="email">Email:</label>
                <Field
                  className="pass"
                  id="email"
                  type="text"
                  placeholder="Enter email"
                  name="email"
                />
              </div>
              <button type="submit">
                {accountTranslate.submitContact[lang]}
              </button>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default Contact;
