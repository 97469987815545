import React from "react";
import { Twirl as HamburgerIcon } from "hamburger-react";
import useWindowDimensions from "../../../useHook/useWindowDimensions";
const HamburgerContent = ({ open, toggleOpen = () => {}, Сontent, title }) => {
  const { height } = useWindowDimensions();
  return (
    <div
      style={height ? { height: height } : {}}
      className={`basket-wrapper ${open ? "" : "closed"}`}
    >
      <div
        style={height ? { height: height } : {}}
        className={`basket ${open ? "" : "closed"}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="basket-modal_top">
          <span>{title}</span>
          <button className="basket-hamburger-button" onClick={toggleOpen}>
            <HamburgerIcon size={30} toggled={open} />
          </button>
        </div>

        {Сontent && Сontent}
      </div>
    </div>
  );
};

export default HamburgerContent;
