export const productPageTranslate = {
  basket: {
    ua: "В кошик",
    ru: "В корзину",
  },
  description: {
    ua: "Опис товару",
    ru: "Описание товара",
  },
};

export const basketTranslate = {
  order: {
    ua: "Оформлення замовлення",
    ru: "Оформление заказа",
  },
  basket: {
    ua: "У вас в кошику",
    ru: "У вас в корзине",
  },
  pay: { ua: "До оплати", ru: "К оплате" },
};

export const orderTranslate = {
  textBottom: {
    ua: `Заповніть просту форму і наші менеджери зв’яжуться з вами, дадуть
    відповідь на будь-які питання та підготують рахунок на оплату.`,

    ru: "Заполните простую форму и наши менеджеры свяжутся с вами, ответят на любые вопросы и подготовят счет на оплату.",
  },
  order: {
    ua: "Оформлення замовлення",
    ru: "Оформление заказа",
  },
  orderThanks: {
    ua: "Дякуємо за замовлення!",
    ru: "Спасибо за заказ!",
  },
  orderConfirm: {
    ua: "Ваше замовлення успішно оформлено",
    ru: "Ваш заказ успешно оформлен",
  },
  call: {
    ua: "Наш менеджер незабаром зв'яжеться з Вами для уточнення деталей оплати та доставки товару.",
    ru: "Наш менеджер скоро свяжется с Вами для уточнения деталей оплаты и доставки товара.",
  },
  email: {
    ua: "Ваші дані для входу були надіслані на вказану електронну адресу.",
    ru: "Ваши данные для входа были отправлены по указанному электронному адресу.",
  },
  orderThanks2: {
    ua: "Дякуємо за Ваш вибір!",
    ru: "Спасибо за Ваш выбор!",
  },
  buttonCatalogue: {
    ua: "Перейти в каталог",
    ru: "Перейти в каталог",
  },
  buttonAccount: {
    ua: "Войти в аккаунт",
    ru: "Войти в аккаунт",
  },
  promo: {
    ua: `Звертаємо вашу увагу на необхідність перевірки даних, надісланих
    на вашу електронну пошту. Будь ласка, уважно перегляньте лист,
    оскільки там містяться всі необхідні дані для входу до вашого
    особистого кабінету.`,
    ru: `Обращаем ваше внимание на необходимость проверки данных, отправленных на вашу электронную почту. Внимательно просмотрите письмо, поскольку там содержатся все необходимые данные для входа в ваш личный кабинет.`,
  },
};

export const orderFormTranslate = {
  title: {
    ua: "Контактна інформація",
    ru: "Контактная информация",
  },
  name: {
    ua: "П.І.Б",
    ru: "Ф.И.О.",
  },
  phone: {
    ua: "Номер телефону",
    ru: "Номер телефонa",
  },
  comment: {
    ua: "Коментар до замовлення",
    ru: "Комментарий к заказу",
  },
  submit: {
    ua: "Оформлення замовлення",
    ru: "Оформить заказ",
  },
};

export const orderBasketTranslate = {
  basket: {
    ua: "Ваше замовлення",
    ru: "Ваш заказ",
  },

  price: {
    ua: "Разом",
    ru: "Вместе",
  },
};

export const accountTranslate = {
  hello: {
    ua: "Раді бачити Вас знову",
    ru: "Рады видеть Вас снова",
  },
  name: {
    ua: "П.І.Б",
    ru: "Ф.И.О.",
  },
  login: {
    ua: "Логiн",
    ru: "Логин",
  },
  phone: {
    ua: "Номер телефону",
    ru: "Номер телефонa",
  },
  submitContact: {
    ua: "Зберегти",
    ru: "Сохранить",
  },

  ordersNumber: {
    ua: "замовлення",
    ru: "заказa",
  },
  orderProductName: {
    ua: "Назва товару",
    ru: "Название товара",
  },
  orderProductQuantity: {
    ua: "Кількість",
    ru: "Количество",
  },
  orderProductPrice: {
    ua: "Ціна",
    ru: "Цена",
  },
  price: {
    ua: "Разом",
    ru: "Вместе",
  },
  new: {
    ua: "Новий",
    ru: "Новый",
  },
  confirmNew: {
    ua: "Підтвердити новий",
    ru: "Подтвердить новый",
  },
};
const statusData = [
  { id: "delivered", name: "Доставлен" },
  { id: "cancelled", name: "Отменен" },
];
export const statusTranslate = {
  pending: {
    ua: "Очікування",
    ru: "Ожидание",
  },
  сonfirmed: {
    ua: "Підтверджений",
    ru: "Подтвержден",
  },
  shipped: {
    ua: "Відправлен",
    ru: "Отправлен",
  },
  delivered: {
    ua: "Доставлен",
    ru: "Доставлен",
  },
  cancelled: {
    ua: "Cкасовано",
    ru: "Отменен",
  },
};

export const filterTranslate = {
  checked: {
    ua: "Обрано",
    ru: "Выбрано",
  },
  filter: {
    ua: "Фільтри",
    ru: "Фильтры",
  },
};

export const footerTranslate = {
  address: {
    ua: "Адреса",
    ru: "Адрес",
  },
  timeWork: {
    ua: "Графік роботи",
    ru: "График работы",
  },

  fur: {
    ua: "Постачальник меблевої фурнітури",
    ru: "Поставщик мебельной фурнитуры",
  },
  contact: {
    ua: "Наші контакти",
    ru: "Наши контакты",
  },
  street: {
    ua: "вулиця Єфімова, 43, Одеса",
    ru: "улица Ефимова, 43, Одесса",
  },
};

export const productTranslate = {
  available: {
    ua: "Є в наявності",
    ru: "Есть в наличие",
  },
};

export const signInTranslate = {
  login: {
    ua: "Логiн",
    ru: "Логин",
  },
  continue: {
    ua: "Продовжити",
    ru: "Продолжить",
  },
  entrance: {
    ua: "Вхід",
    ru: "Вход",
  },
  error: {
    ua: "Невірний пароль чи логін",
    ru: "Неправильный пароль или логин",
  },
  record: {
    ua: "У вас ще немає облікового запису клієнта?",
    ru: "У вас нет учетной записи клиента?",
  },
  email: {
    ua: "При оформленні замовлення, обліковий запис створюється автоматично, пароль та логін прийде на пошту",
    ru: "При оформлении заказа учетная запись создается автоматически, пароль и логин придет на почту",
  },
  signUp: {
    ua: "Зареєструватися",
    ru: "Зарегистрироваться",
  },
};
