import React from "react";
import { useSelector } from "react-redux";
import cancel_icon from "../../../style/images/cancel_icon.png";
import { filterTranslate } from "../../../utils/translate";
const CheckedOptions = ({ setFilters, lang }) => {
  const filtertsCheckedNames = useSelector(
    (state) => state.catalog.filtertsCheckedNames
  );

  const cancelOption = ({ attribute_id, name }) => {
    setFilters((prevFilters) =>
      prevFilters.map((filter) => {
        return filter.id === attribute_id
          ? {
              ...filter,
              options: filter.options.map((option) => {
                return option[`name_${lang}`] === name
                  ? { ...option, checked: false }
                  : option;
              }),
            }
          : filter;
      })
    );
  };

  if (filtertsCheckedNames && filtertsCheckedNames.length > 0) {
    return (
      <div className="checkedOptions_main">
        <span className="checkedOptions_title">
          {filterTranslate.checked[lang]}
        </span>
        <div className="checkedOptions_wrapper">
          {filtertsCheckedNames &&
            filtertsCheckedNames.map(({ option_names, attribute_id }) =>
              option_names.map((name) => (
                <div
                  onClick={() =>
                    cancelOption({
                      attribute_id,
                      name: name[`option_name_${lang}`],
                    })
                  }
                  className="checkedOptions"
                  key={name[`option_name_${lang}`]}
                >
                  {name[`option_name_${lang}`]}{" "}
                  <img src={cancel_icon} alt="cancel icon" />
                </div>
              ))
            )}
        </div>
      </div>
    );
  }
};

export default CheckedOptions;
