import React, { useEffect } from "react";
import UseSliderProductModal from "../../../useHook/slider/UseSliderProductModal";

const ProductViewerModal = ({ visible, images, onClose }) => {
  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [visible]);
  if (visible)
    return (
      <div className="modal" onClick={onClose}>
        <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
          <UseSliderProductModal images={images} />
        </div>
      </div>
    );
};

export default ProductViewerModal;
