import React from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as HomeIcon } from "../../style/images/home_icon.svg";
import { useLanguage } from "../../utils/LanguageContext";
import useWindowDimensions from "../../useHook/useWindowDimensions";
import "../../style/nav.css";
const Nav = () => {
  const { languageSlug, changeLanguageSlug } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  const { widthScreen } = useWindowDimensions();

  const handleLanguageChange = (lang) => {
    const currentPath = location.pathname;
    const pathWithoutLanguageSlug = currentPath.replace(`/${languageSlug}`, "");

    changeLanguageSlug(lang);
    const langHtml = lang === "ua" ? "uk" : lang;
    document.documentElement.setAttribute("lang", langHtml);
    navigate(`/${lang}${pathWithoutLanguageSlug}`, { replace: true });
  };

  return (
    <>
      {widthScreen > 900 ? (
        <nav className="navMain">
          <div className="navMain_link-container">
            <NavLink to={`/${languageSlug}/`}>
              <HomeIcon />
            </NavLink>
            <NavLink to={`/${languageSlug}/catalog`} className="">
              Каталог
            </NavLink>
          </div>
          <div className="navMain_language">
            <span
              style={{
                color: languageSlug === "ua" ? "var(--green008947)" : "#fff",
                backgroundColor: languageSlug === "ua" ? "#fff" : "",
              }}
              onClick={() => handleLanguageChange("ua")}
            >
              UA
            </span>
            <span>|</span>
            <span
              style={{
                color: languageSlug === "ru" ? "var(--green008947)" : "#fff",
                backgroundColor: languageSlug === "ru" ? "#fff" : "",
              }}
              onClick={() => handleLanguageChange("ru")}
            >
              RU
            </span>
          </div>
        </nav>
      ) : (
        <nav className="navMainMobile">
          <div className="navMainMobile_link-container">
            <NavLink to={`/${languageSlug}/`}>
              <HomeIcon />
            </NavLink>
            <NavLink to={`/${languageSlug}/catalog`} className="">
              Каталог
            </NavLink>
          </div>
          <div className="navMainMobile_language">
            <span
              style={{
                color:
                  languageSlug === "ru" ? "#008947A6" : "var(--green008947)",

                backgroundColor: languageSlug === "ua" ? "#fff" : "",
              }}
              onClick={() => handleLanguageChange("ua")}
            >
              UA
            </span>
            <span style={{ color: "var(--green008947)" }}>|</span>
            <span
              style={{
                color:
                  languageSlug === "ua" ? "#008947A6" : "var(--green008947)",
                backgroundColor: languageSlug === "ru" ? "#fff" : "",
              }}
              onClick={() => handleLanguageChange("ru")}
            >
              RU
            </span>
          </div>
        </nav>
      )}
    </>
  );
};

export default Nav;
