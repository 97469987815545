// import React, { useEffect, useState, useRef } from "react";
// import { useParams } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import FilterWithProductHelmet from "./components/FilterWithProductHelmet";
// import {
//   getProductsByCatagory,
//   getCategoryById,
//   getFiltersCategoryById,
//   toggleOpenHamFilter,
// } from "../../store/slices/catalogSlice";
// import { makeUrl } from "../../utils/makeUrl";
// import Breadcrumbs from "../../utils/Breadcrumbs";
// import ProductCard from "./components/ProductCard";
// import ProductCardHorizontal from "./components/ProductCardHorizontal";
// import Filters from "./components/Filters";
// import Pagination from "../../useHook/pagination/Pagination";
// import Exel from "../../utils/saveExcel/Exel";
// import { useLanguage } from "../../utils/LanguageContext";
// import { useDebounce } from "../../useHook/useDebounce";
// import { ReactComponent as Var_1 } from "../../style/images/catalog_var1.svg";
// import { ReactComponent as Var_2 } from "../../style/images/catalog_var2.svg";
// import useWindowDimensions from "../../useHook/useWindowDimensions";
// import Hamburger from "../../components/hamburger/Hamburger";
// const FilterWithProducts = () => {
//   const { subCategory, category } = useParams();
//   const { widthScreen } = useWindowDimensions();
//   const [filters, setFilters] = useState([]);

//   // mobile button
//   const hamFilter = useSelector((state) => state.catalog.hamFilter);

//   const dispatch = useDispatch();
//   const products = useSelector((state) => state.catalog.products);
//   const totalPages = useSelector((state) => state.catalog.totalProducts);
//   const { languageSlug } = useLanguage();
//   const [style, setStyle] = useState("var_1");
//   // pagination
//   const productListRef = useRef(null);
//   const [PageSize, setPageSize] = useState(12);
//   const [currentPage, setCurrentPage] = useState(1);
//   if (currentPage - 1 > totalPages / PageSize) {
//     setCurrentPage(1);
//   }

//   const initialFilters = useSelector((state) => state.catalog.filters);
//   const filtertsCheckedNames = useSelector(
//     (state) => state.catalog.filtertsCheckedNames
//   );

//   const priceInitialRange = useSelector(
//     (state) => state.catalog.priceInitialRange
//   );
//   const priceRange = useSelector((state) => state.catalog.priceRange);

//   useEffect(() => {
//     dispatch(getFiltersCategoryById(subCategory));
//   }, []);

//   const debouncedSearchTerm = useDebounce(priceRange, 150);
//   const debouncedSearchTermFilters = useDebounce(filtertsCheckedNames, 50);

//   useEffect(() => {
//     dispatch(
//       getProductsByCatagory({
//         category: subCategory,
//         limit: PageSize,
//         offset: currentPage === 1 ? 0 : currentPage * PageSize - PageSize,
//         search: makeUrl({
//           priceRange,
//           filtertsCheckedNames: filtertsCheckedNames.map(
//             ({ attribute_id, option_names }) => ({
//               attribute_id,
//               option_names: option_names.map((item) => item.option_name_ua),
//             })
//           ),
//         }),
//       })
//     );
//   }, [debouncedSearchTerm, debouncedSearchTermFilters, currentPage]);
//   useEffect(() => {
//     if (productListRef.current) {
//       productListRef.current.scrollIntoView({ behavior: "smooth" });
//     }
//   }, [currentPage]);
//   // breadcrumbs
//   const [breadcrumbs, setBreadcrumbs] = useState([]);
//   useEffect(() => {
//     const fetchBreadcrumbs = async () => {
//       const cat = await dispatch(getCategoryById(category));
//       const sub = await dispatch(getCategoryById(subCategory));

//       if (subCategory && category) {
//         setBreadcrumbs([
//           { title: "Каталог", to: "catalog" },
//           { title: cat.payload[`name_${languageSlug}`], to: category },
//           { title: sub.payload[`name_${languageSlug}`], to: subCategory },
//         ]);
//       }
//     };

//     fetchBreadcrumbs();
//   }, [languageSlug]);

//   return (
//     <>
//       <FilterWithProductHelmet lang={languageSlug} breadcrumbs={breadcrumbs} />
//       <div className="productWithFilters" ref={productListRef}>
//         {widthScreen > 900 && (
//           <div className="product_filters">
//             <Filters
//               lang={languageSlug}
//               initialFilters={initialFilters}
//               priceInitialRange={priceInitialRange}
//               filters={filters}
//               setFilters={setFilters}
//             />
//           </div>
//         )}

//         <div className="product_main">
//           <div className="catalogStyleWrapper">
//             <Breadcrumbs params={breadcrumbs} />

//             {widthScreen > 900 && (
//               <div
//                 style={{ display: "flex", gap: "0 15px", alignItems: "center" }}
//               >
//                 <Exel title={"Прайс"} subCategory={subCategory} />
//                 <div className="catalogStyle_images">
//                   <Var_1
//                     className={style === "var_1" ? "active" : "inactive"}
//                     onClick={() => setStyle("var_1")}
//                     id="var_1"
//                   />
//                   <Var_2
//                     onClick={() => setStyle("var_2")}
//                     className={style === "var_2" ? "active" : "inactive"}
//                     id="var_2"
//                   />
//                 </div>
//               </div>
//             )}
//           </div>
//           {widthScreen < 900 && (
//             <div className="catalogMobileButton-wrapper">
//               <Hamburger
//                 open={hamFilter}
//                 Icon={
//                   <button className="catalogMobileButton_filterButton">
//                     Фільтри
//                   </button>
//                 }
//                 toggle={toggleOpenHamFilter}
//                 Сontent={
//                   <div className="product_filters">
//                     <Filters
//                       lang={languageSlug}
//                       initialFilters={initialFilters}
//                       priceInitialRange={priceInitialRange}
//                       title={false}
//                       filters={filters}
//                       setFilters={setFilters}
//                     />
//                   </div>
//                 }
//                 title={"Фильтры"}
//               />
//             </div>
//           )}
//           {style === "var_1" ? (
//             <div className="catalog_card-containerForProduct">
//               {products.length > 0 &&
//                 products.map((product) => (
//                   <ProductCard
//                     lang={languageSlug}
//                     key={product.id}
//                     product={product}
//                   />
//                 ))}
//             </div>
//           ) : (
//             <div className="catalog_cardHorizontal-containerForProduct">
//               {products.length > 0 &&
//                 products.map((product) => (
//                   <ProductCardHorizontal
//                     lang={languageSlug}
//                     key={product.id}
//                     product={product}
//                   />
//                 ))}
//             </div>
//           )}
//           <Pagination
//             className="pagination-bar"
//             currentPage={currentPage}
//             totalCount={totalPages}
//             pageSize={PageSize}
//             onPageChange={(page) => setCurrentPage(page)}
//           />
//         </div>
//       </div>
//     </>
//   );
// };

// export default FilterWithProducts;

import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FilterWithProductHelmet from "./components/FilterWithProductHelmet";
import {
  getProductsByCatagory,
  getCategoryById,
  getFiltersCategoryById,
  toggleOpenHamFilter,
} from "../../store/slices/catalogSlice";
import { makeUrl } from "../../utils/makeUrl";
import Breadcrumbs from "../../utils/Breadcrumbs";
import ProductCard from "./components/ProductCard";
import ProductCardHorizontal from "./components/ProductCardHorizontal";
import Filters from "./components/Filters";
import Pagination from "../../useHook/pagination/Pagination";
import Exel from "../../utils/saveExcel/Exel";
import { useLanguage } from "../../utils/LanguageContext";
import { useDebounce } from "../../useHook/useDebounce";
import { ReactComponent as Var_1 } from "../../style/images/catalog_var1.svg";
import { ReactComponent as Var_2 } from "../../style/images/catalog_var2.svg";
import useWindowDimensions from "../../useHook/useWindowDimensions";
import Hamburger from "../../components/hamburger/Hamburger";

const FilterWithProducts = () => {
  const { subCategory, category } = useParams();
  const { widthScreen } = useWindowDimensions();
  const [filters, setFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Добавляем состояние загрузки
  const hamFilter = useSelector((state) => state.catalog.hamFilter);
  const dispatch = useDispatch();
  const products = useSelector((state) => state.catalog.products);
  const totalPages = useSelector((state) => state.catalog.totalProducts);
  const { languageSlug } = useLanguage();
  const [style, setStyle] = useState("var_1");
  const productListRef = useRef(null);
  const [PageSize, setPageSize] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);

  if (currentPage - 1 > totalPages / PageSize) {
    setCurrentPage(1);
  }

  const initialFilters = useSelector((state) => state.catalog.filters);
  const filtertsCheckedNames = useSelector(
    (state) => state.catalog.filtertsCheckedNames
  );

  const priceInitialRange = useSelector(
    (state) => state.catalog.priceInitialRange
  );
  const priceRange = useSelector((state) => state.catalog.priceRange);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await dispatch(getFiltersCategoryById(subCategory));
      await dispatch(
        getProductsByCatagory({
          category: subCategory,
          limit: PageSize,
          offset: currentPage === 1 ? 0 : currentPage * PageSize - PageSize,
          search: makeUrl({
            priceRange,
            filtertsCheckedNames: filtertsCheckedNames.map(
              ({ attribute_id, option_names }) => ({
                attribute_id,
                option_names: option_names.map((item) => item.option_name_ua),
              })
            ),
          }),
        })
      );
      setIsLoading(false);
    };

    fetchData();
  }, [subCategory, currentPage]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(
        getProductsByCatagory({
          category: subCategory,
          limit: PageSize,
          offset: currentPage === 1 ? 0 : currentPage * PageSize - PageSize,
          search: makeUrl({
            priceRange,
            filtertsCheckedNames: filtertsCheckedNames.map(
              ({ attribute_id, option_names }) => ({
                attribute_id,
                option_names: option_names.map((item) => item.option_name_ua),
              })
            ),
          }),
        })
      );
    };
    fetchData();
  }, [priceRange, filtertsCheckedNames]);

  useEffect(() => {
    if (productListRef.current) {
      productListRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentPage]);

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  useEffect(() => {
    const fetchBreadcrumbs = async () => {
      const cat = await dispatch(getCategoryById(category));
      const sub = await dispatch(getCategoryById(subCategory));

      if (subCategory && category) {
        setBreadcrumbs([
          { title: "Каталог", to: "catalog" },
          { title: cat.payload[`name_${languageSlug}`], to: category },
          { title: sub.payload[`name_${languageSlug}`], to: subCategory },
        ]);
      }
    };

    fetchBreadcrumbs();
  }, [languageSlug, category, subCategory]);

  return (
    <>
      <FilterWithProductHelmet lang={languageSlug} breadcrumbs={breadcrumbs} />
      <div className="productWithFilters" ref={productListRef}>
        {widthScreen > 900 && (
          <div className="product_filters">
            <Filters
              lang={languageSlug}
              initialFilters={initialFilters}
              priceInitialRange={priceInitialRange}
              filters={filters}
              setFilters={setFilters}
            />
          </div>
        )}

        <div className="product_main">
          <div className="catalogStyleWrapper">
            <Breadcrumbs params={breadcrumbs} />

            {widthScreen > 900 && (
              <div
                style={{ display: "flex", gap: "0 15px", alignItems: "center" }}
              >
                <Exel title={"Прайс"} subCategory={subCategory} />
                <div className="catalogStyle_images">
                  <Var_1
                    className={style === "var_1" ? "active" : "inactive"}
                    onClick={() => setStyle("var_1")}
                    id="var_1"
                  />
                  <Var_2
                    onClick={() => setStyle("var_2")}
                    className={style === "var_2" ? "active" : "inactive"}
                    id="var_2"
                  />
                </div>
              </div>
            )}
          </div>
          {widthScreen < 900 && (
            <div className="catalogMobileButton-wrapper">
              <Hamburger
                open={hamFilter}
                Icon={
                  <button className="catalogMobileButton_filterButton">
                    Фільтри
                  </button>
                }
                toggle={toggleOpenHamFilter}
                Сontent={
                  <div className="product_filters">
                    <Filters
                      lang={languageSlug}
                      initialFilters={initialFilters}
                      priceInitialRange={priceInitialRange}
                      title={false}
                      filters={filters}
                      setFilters={setFilters}
                    />
                  </div>
                }
                title={"Фильтры"}
              />
            </div>
          )}
          {!isLoading &&
            (style === "var_1" ? (
              <div className="catalog_card-containerForProduct">
                {products.length > 0 &&
                  products.map((product) => (
                    <ProductCard
                      lang={languageSlug}
                      key={product.id}
                      product={product}
                    />
                  ))}
              </div>
            ) : (
              <div className="catalog_cardHorizontal-containerForProduct">
                {products.length > 0 &&
                  products.map((product) => (
                    <ProductCardHorizontal
                      lang={languageSlug}
                      key={product.id}
                      product={product}
                    />
                  ))}
              </div>
            ))}

          {!isLoading && (
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={totalPages}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default FilterWithProducts;
